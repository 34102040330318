<template>
  <div>
    <v-card
      class="mb-12"
      elevation="0"
    >
      <v-form v-model="formValid" ref="propertyForm">
        <v-row class="mt-1">
          <v-col>
            <v-text-field
              :rules="[validation.required, validation.maxLength(255)]"
              v-model.trim="property.name"
              label="Název objektu"
              outlined
              class="rounded-0">
            </v-text-field>
          </v-col>
          <v-col cols="2">
          </v-col>
          <v-col>
            <div class="d-flex">
              <div class="rating">
                <span class="pl-2 rating-text">Počet hvězdiček</span>
                <v-rating
                  color="warning"
                  empty-icon="mdi-star-outline"
                  full-icon="mdi-star"
                  half-icon="mdi-star-half-full"
                  hover
                  length="5"
                  size="25"
                  v-model="property.stars"
                ></v-rating>
              </div>
              <span class="rating-stars-numeric pb-2">( {{property.stars}} )</span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="property.hostLanguages"
              label="Jazyk hostitele"
              outlined
              multiple
              small-chips
              deletable-chips
              hide-selected
              :items="['CZ']"
              :rules="[validation.required]"
              class="rounded-0">
            </v-select>
          </v-col>
          <v-col>
            <v-text-field
              v-model="property.contactPersonName"
              label="Kontaktní osoba"
              outlined
              :readonly="fetchingUserData"
              :rules="[validation.required, validation.maxLength(255)]"
              class="rounded-0">
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="property.contactPersonPhone"
              label="Kontaktní telefon"
              outlined
              type="tel"
              :readonly="fetchingUserData"
              :rules="[validation.required, validation.phone, validation.maxLength(255)]"
              class="rounded-0">
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="property.contactPersonEmail"
              label="Kontaktní email"
              outlined
              :readonly="fetchingUserData"
              type="email"
              :rules="[validation.required, validation.email, validation.maxLength(255)]"
              class="rounded-0">
            </v-text-field>
          </v-col>
          <v-col>
            <v-btn
              outlined
              :loading="fetchingUserData"
              @click="fillUserData"
              class="rounded-0">
              Vyplnit z profilu
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <NominatimSearch v-model="property.locality"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="property.descriptionPreview"
              :rules="[validation.maxLength(255), validation.required]"
              label="Rychlý popis"
              hint="Tento popis bude vidět ve vyhledávání"
              outlined
              class="rounded-0"
              rows="4"
            ></v-textarea>
            <p>Popis</p>
            <TiptapVuetify
              :extensions="extensions"
              placeholder="Sem napište popis"
              rows="5"
              v-model="property.description"
            ></TiptapVuetify>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-btn
      color="primary"
      @click="goToStep(3)"
      :disabled="!formValid || !property.description"
    >
      Pokračovat
    </v-btn>

    <v-btn
      text
      @click="goToStep(1)"
    >
      Předchozí krok
    </v-btn>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import NominatimSearch from '@/components/search/NominatimSearch'
import { EventBus } from '@/events/event_bus'

export default {
  name: 'Step2',
  components: { NominatimSearch, TiptapVuetify },
  data () {
    return {
      fetchingUserData: false,
      ownerData: null,
      formValid: true,
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ]
    }
  },
  methods: {
    /**
     * Fills form with owner data from ownerData object
     * If ownerData is null, it fetches them from backend
     *
     * @return void
     */
    async fillUserData () {
      if (!this.ownerData) {
        this.fetchingUserData = true
        this.ownerData = (await this.$http.get(`/hosts/${this.property.ownerId}`)).data
        this.fetchingUserData = false
      }

      this.property.contactPersonName = `${this.ownerData.firstName} ${this.ownerData.lastName}`
      this.property.contactPersonPhone = this.ownerData.phone
      this.property.contactPersonEmail = this.ownerData.email
    },
    ...mapMutations({
      goToStep: 'propertyCard/goToStep'
    })
  },
  computed: {
    ...mapGetters({
      property: 'propertyCard/property'
    })
  },
  created () {
    EventBus.$on('reset-forms', () => {
      this.$refs.propertyForm.reset()
    })
  }
}
</script>

<style scoped>
.rating-stars-numeric{
  align-self: flex-end;
}
>>>.tiptap-vuetify-editor .ProseMirror{
  min-height: 200px;
}
</style>
