<template>
  <div>
    <h1>Vyberte typ objektu</h1>
    <v-container class="content" v-if="propertyTypes.length">
      <v-row>
        <v-col
          v-for="(item, index) in propertyTypes"
          :key="index"
          cols="auto"
        >
          <v-card
            color="#fff"
            @click="selectPropertyType(item.id)"
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="text-h5"
                  v-text="item.name"
                ></v-card-title>

                <v-card-subtitle v-text="item.description"></v-card-subtitle>

                <v-card-actions>
                </v-card-actions>
              </div>

              <v-avatar
                class="ma-3"
                size="125"
                tile
              >
                <v-icon class="item-icon" v-text="item.icon" color="blue darken-1" x-large></v-icon>
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-skeleton-loader
      type="card"
      v-else
    />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'Step1',
  data () {
    return {
      propertyTypes: []
    }
  },
  methods: {
    selectPropertyType (id) {
      this.setPropertyType(id)
      this.goToStep(2)
    },
    ...mapMutations({
      goToStep: 'propertyCard/goToStep',
      setPropertyType: 'propertyCard/setPropertyTypeId'
    })
  },
  computed: {
    ...mapGetters({
      property: 'propertyCard/property'
    })
  },
  created () {
    this.$http.get('/property-types')
      .then(res => {
        this.propertyTypes = res.data
      })
  }
}
</script>

<style scoped>

</style>
