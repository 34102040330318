<template>
  <form>
    <div class="display-1 text-center pt-3 pb-3">Hodnocení ubytování</div>
    <v-row>
      <v-col cols="6">
        <v-textarea
          v-model="form.text"
          label="Hodnocení ubytování"
          outlined
          :readonly="review.approvalProcessState >= 3"
          color="red"
          rows="5">
        </v-textarea>
      </v-col>
      <v-col class="align-center" cols="3">
        <div class="d-flex">
          <div class="rating">
            <span class="pl-2 rating-text">Čistota ubytování:</span>
            <v-rating
              color="warning"
              empty-icon="mdi-star-outline"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              half-increments
              hover
              :readonly="review.approvalProcessState >= 3"
              length="5"
              size="25"
              v-model="form.cleanliness"
            ></v-rating>
          </div>
          <span class="rating-stars-numeric pb-2">( {{form.cleanliness}} )</span>
        </div>
        <div class="d-flex">
          <div class="rating">
            <span class="pl-2 rating-text">Vybavení:</span>
            <v-rating
              color="warning"
              empty-icon="mdi-star-outline"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              half-increments
              hover
              :readonly="review.approvalProcessState >= 3"
              length="5"
              size="25"
              v-model="form.equipment"
            ></v-rating>
          </div>
          <span class="rating-stars-numeric pb-2">( {{form.equipment}} )</span>
        </div>
        <div class="d-flex">
          <div class="rating">
            <span class="pl-2 rating-text">Poměr cena/kvalita:</span>
            <v-rating
              color="warning"
              empty-icon="mdi-star-outline"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              half-increments
              hover
              :readonly="review.approvalProcessState >= 3"
              length="5"
              size="25"
              v-model="form.qualityPriceRatio"
            ></v-rating>
          </div>
          <span class="rating-stars-numeric pb-2">( {{form.qualityPriceRatio}} )</span>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="d-flex">
          <div class="rating">
            <span class="pl-2 rating-text">Okolí ubytování:</span>
            <v-rating
              color="warning"
              empty-icon="mdi-star-outline"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              half-increments
              hover
              :readonly="review.approvalProcessState >= 3"
              length="5"
              size="25"
              v-model="form.locality"
            ></v-rating>
          </div>
          <span class="rating-stars-numeric pb-2">( {{form.locality}} )</span>
        </div>
        <div class="d-flex">
          <div class="rating">
            <span class="pl-2 rating-text">Hostitel/Personál:</span>
            <v-rating
              color="warning"
              empty-icon="mdi-star-outline"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              half-increments
              hover
              :readonly="review.approvalProcessState >= 3"
              length="5"
              size="25"
              v-model="form.hostStaff"
            ></v-rating>
          </div>
          <span class="rating-stars-numeric pb-2">( {{form.hostStaff}} )</span>
        </div>

        <div class="d-flex">
          <div class="rating">
            <span class="pl-2 rating-text">Konečné hodnocení:</span>
            <v-rating
              color="warning"
              empty-icon="mdi-star-outline"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              half-increments
              hover
              length="5"
              size="25"
              readonly
              :value="+finalRating"
            ></v-rating>
          </div>
          <span class="rating-stars-numeric pb-2">( {{finalRating}} )</span>
        </div>
      </v-col>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          color="green"
          class="mr-4"
          @click="approveWithChanges"
          v-if="review.approvalProcessState === 1"
        >
          Akceptovat
        </v-btn>
        <v-btn
          color="red"
          class="mr-4"
          @click="reject"
          v-if="review.approvalProcessState === 1"
        >
          Odmítnout (zahodí změny)
        </v-btn>
      </v-col>
    </v-row>
  </form>
</template>

<script>
export default {
  name: 'AdminReviewForm',
  props: {
    review: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      form: {
        text: null,
        cleanliness: null,
        equipment: null,
        qualityPriceRatio: null,
        locality: null,
        hostStaff: null
      }
    }
  },
  computed: {
    finalRating () {
      return ((this.form.cleanliness +
        this.form.equipment +
        this.form.qualityPriceRatio +
        this.form.locality +
        this.form.hostStaff
      ) / 5).toFixed(1)
    },
    valid () {
      return (this.form.cleanliness !== null &&
        this.form.equipment !== null &&
        this.form.qualityPriceRatio !== null &&
        this.form.locality !== null &&
        this.form.hostStaff !== null) && this.formValid
    }
  },
  methods: {
    approveWithChanges () {
      this.runningRequest = true
      this.$http.put(`/admin/reviews/${this.review.id}`, {
        ...this.form,
        approvalProcessState: 3
      })
        .then(res => {
          this.$emit('edit-success')
        })
        .catch(() => {
          this.$emit('edit-failure')
        })
    },
    reject () {
      this.$http.put(`/admin/reviews/${this.review.id}`, {
        ...this.review,
        approvalProcessState: 2
      })
        .then(res => {
          this.$emit('edit-success')
        })
        .catch(() => {
          this.$emit('edit-failure')
        })
    }
  },
  created () {
    this.form = {
      text: this.review.text,
      cleanliness: this.review.cleanliness,
      equipment: this.review.equipment,
      qualityPriceRatio: this.review.qualityPriceRatio,
      locality: this.review.locality,
      hostStaff: this.review.hostStaff,
      propertyId: this.review.property.id
    }
  }
}
</script>

<style scoped>
.rating-stars-numeric{
  align-self: flex-end;
}
.rating-text{
  color: darkblue;
  font-weight: bold;
}
>>>.rating button{
  padding-top: 0;
}
</style>
