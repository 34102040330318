<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1>Topování</h1>
      </v-col>
    </v-row>
    <v-form
      v-if="!loadingSettings"
      v-model="formValid"
      ref="form"
    >
      <v-row>
        <v-col cols="2">
          <v-text-field
            label="Velikost příhozu"
            dense
            class="rounded-0"
            outlined
            v-model="form.bidCount"
            type="number"
            :rules="[validation.greaterThan(-1), validation.required]"
            min="0"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="form.views"
            label="Počet zobrazení"
            :step="globalAdvertisingSettings.promoViewUnitSize"
            :rules="[validation.required, validation.greaterThan(0)]"
            dense
            class="rounded-0"
            outlined
            type="number"
            min="0"
            @focusout="checkFormInputs"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
            label="Typ topování"
            dense
            v-model="form.type"
            :items="promoTypes"
            :rules="[validation.required]"
            class="rounded-0"
            outlined>
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <vc-date-picker
            v-model="dateFilter"
            is-range
            :columns="2"
            color="blue"
            :popover="{ visibility: 'focus' }">
            <template v-slot="{ dateFilter: inputValue, inputEvents }">
              <div class="d-flex justify-center items-center">
                <v-text-field
                  outlined
                  :value="getLocalizedDate(dateFilter.start)"
                  v-on="inputEvents.start"
                  hide-details
                  readonly
                  :rules="[validation.required]"
                  placeholder="Od"
                  class="rounded-0 border-right-none"
                  dense
                />
                <v-text-field
                  class="rounded-0 border-left-none"
                  outlined
                  :value="getLocalizedDate(dateFilter.end)"
                  v-on="inputEvents.end"
                  hide-details
                  dense
                  readonly
                  :rules="[validation.required]"
                  placeholder="Do"
                />
              </div>
            </template>
          </vc-date-picker>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>Předpokládaná doba topování</h3>
          <div><span>5</span> Dnů</div>
        </v-col>
        <v-col>
          <h3>Celková cena</h3>
          <div><span>{{price}}</span> Kč</div>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer />
        <v-col class="d-flex flex-column align-end">
          <div
            class="red--text darken-4"
            v-if="promoExists"
          >Na zadané období již byla vytvořena kampaň</div>
          <v-btn
            color="primary"
            class="rounded-0 justify-end"
            :loading="savingData"
            :disabled="!formValid || promoExists"
            @click="save"
          >
            Uložit
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-skeleton-loader
      type="article"
      v-else
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NewPromoForm',
  data () {
    return {
      formValid: true,
      form: {
        type: null,
        bidCount: null,
        views: null,
        dateFrom: null,
        dateTo: null
      },
      dateFilter: {},
      promoTypes: [
        { text: 'Na hlavní stránce', value: 0 },
        { text: 'Ve vyhledávání', value: 1 }
      ],
      savingData: false
    }
  },
  methods: {
    checkFormInputs () {
      if (this.form.views % this.globalAdvertisingSettings.promoViewUnitSize !== 0) {
        this.form.views = Math.floor(this.form.views / this.globalAdvertisingSettings.promoViewUnitSize) * this.globalAdvertisingSettings.promoVewUnitSize
      }
    },
    save () {
      this.savingData = true
      this.$http.post(`/host/properties/${this.property.id}/promo`, this.form)
        .then(res => {
          this.setAlerts([{
            message: 'Data byla uložena',
            type: 'success'
          }])
          this.$store.state.detailViews.property.promo.push(res.data)
          this.$refs.form.reset()
        })
        .catch(err => {
          if (err.response.data.message === 'NOT_ENOUGH_CREDIT') {
            this.setAlerts([{
              message: 'Nedostatek kreditu',
              type: 'error'
            }])
          }
          if (err.response.data.message === 'PROMO_ALREADY_EXISTS') {
            this.setAlerts([{
              message: 'Na zadané období již byla vytvořena kampaň',
              type: 'error'
            }])
          }
        })
        .finally(() => {
          this.savingData = false
        })
    },
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  computed: {
    ...mapGetters({
      property: 'detailViews/property',
      globalAdvertisingSettings: 'detailViews/globalAdvertisingSettings',
      loadingSettings: 'detailViews/loadingSettings'
    }),
    promoExists () {
      if (!this.form.dateTo || !this.form.dateFrom) {
        return false
      }
      const filtered = this.property.promo.filter(promo => {
        // rozdilny typ
        if (promo.type !== this.form.type) {
          return false
        }
        // kontrola datumu
        const formDateFrom = new Date(this.form.dateFrom)
        const formDateTo = new Date(this.form.dateTo)
        const promoDateFrom = new Date(promo.dateFrom)
        const promoDateTo = new Date(promo.dateTo)
        // zacatek v existujicim
        if (promoDateFrom <= formDateFrom && formDateFrom <= promoDateTo) {
          return true
        }
        // konec v existujicim
        if (promoDateFrom <= formDateTo && formDateTo <= promoDateTo) {
          return true
        }

        return false
      })
      return !!filtered.length
    },
    price () {
      if (!this.form.views) {
        return '-'
      }
      return this.form.views * this.globalAdvertisingSettings.promoViewPrice
    }
  },
  watch: {
    dateFilter: {
      deep: true,
      handler (val) {
        if (val.start) {
          this.form.dateFrom = this.dateFilter.start.toISOString().slice(0, 10)
        }

        if (val.end) {
          this.form.dateTo = this.dateFilter.end.toISOString().slice(0, 10)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
