<template>
  <v-card>
    <v-card-text>
      <v-row>
        <div>
          <h2>Rozsah</h2>
          {{ (new Date(promo.dateFrom)).toLocaleDateString() }} -
          {{ (new Date(promo.dateTo)).toLocaleDateString() }}
          <h3>Typ</h3>
          {{ promo.type === 0 ? 'Na hlavní stránce' : 'Ve vyhledávání' }}
          <h3>Počet zobrazení</h3>
          Celkem: {{ promo.views }}, zbývá {{ promo.remainingViews }}
        </div>
        <v-spacer />
        <div>
          <v-icon
            @click="deleteDialog = true"
          >
            mdi-delete
          </v-icon>
        </div>
        <v-dialog
          max-width="300px"
          v-model="deleteDialog"
        >
          <DeleteResource
            v-if="deleteDialog"
            :resource="promo"
            @delete-failure="showDeleteFailure"
            @delete-success="deleteSuccessHandler"
            :resource-type="`host/properties/${property.id}/promo`"
          />
        </v-dialog>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="Velikost příhozu"
            min="0"
            type="number"
            v-model.number="form.bidCount"
          />
        </v-col>
        <v-col>
          <v-checkbox
            label="Aktivní"
            v-model="form.active"
          />
        </v-col>
      </v-row>
      <v-row>
          <v-spacer/>
        <v-btn
          color="error"
          @click="deleteChanges"
        >
          Zahodit změny
        </v-btn>
          <v-btn
            @click="save"
            :loading="savingData"
            color="success">
            Uložit
          </v-btn>
      </v-row>

      <v-form v-model="additionalViewsFormValid" ref="additionalViewsForm">
        <h3>Přikoupit zobrazení</h3>
        <v-row>
          <v-col cols="6">
            <v-text-field
              type="number"
              min="0"
              :step="globalAdvertisingSettings.promoViewUnitSize"
              :rules="[validation.required, validation.greaterThan(0)]"
              label="Počet"
              v-model="additionalViewsForm.views"
            />
          </v-col>
          <v-col cols="6" />
          <v-col cols="6" class="d-flex justify-end">
            <div>
              Cena {{additionalViewsPrice}}
            </div>
            <v-btn
              :disabled="!additionalViewsFormValid"
              :loading="savingData"
              color="warning"
              @click="buyViews"
            >
              Přikoupit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DeleteResource from '@/components/DeleteResource'
import archiveHelpers from '@/mixins/local/archiveHelpers'

export default {
  name: 'PromoHistoryCard',
  components: { DeleteResource },
  mixins: [archiveHelpers],
  props: {
    promo: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      savingData: false,
      form: {},
      additionalViewsForm: {
        views: null
      },
      additionalViewsFormValid: true
    }
  },
  methods: {
    save () {
      this.savingData = true
      this.$http.put(`/host/properties/${this.property.id}/promo/${this.promo.id}`, {
        ...this.promo,
        ...this.form
      })
        .then(res => {
          window.scrollTo(0, 0)
          this.setAlerts([{
            message: 'Data byla uložena',
            type: 'success'
          }])
          this.updatePropertyPromo({
            id: this.promo.id,
            promo: res.data
          })
        })
        .finally(() => {
          this.savingData = false
        })
    },
    buyViews () {
      this.savingData = true
      this.$http.put(`/host/properties/${this.property.id}/promo/${this.promo.id}/views`, this.additionalViewsForm)
        .then(res => {
          this.updatePropertyPromo({
            id: this.promo.id,
            promo: res.data
          })
          this.$refs.additionalViewsForm.reset()
          window.scrollTo(0, 0)
          this.setAlerts([{
            message: 'Data byla uložena',
            type: 'success'
          }])
        })
        .catch(err => {
          if (err.response.data.message) {
            if (err.response.data.message === 'NOT_ENOUGH_CREDIT') {
              this.setAlerts([{
                message: 'Nedostatek kreditu',
                type: 'error'
              }])
            }
          }
        })
        .finally(() => {
          this.savingData = false
        })
    },
    deleteChanges () {
      this.form = {
        ...this.promo
      }
    },
    deleteSuccessHandler () {
      this.deleteDialog = false
      this.$store.dispatch('alerts/setAlerts', [{
        message: 'Úspěšně smazáno a kredit připsán na účet',
        type: 'success'
      }])
      window.scrollTo(0, 0)

      const index = this.property.promo.indexOf(this.promo)
      this.property.promo.splice(index, 1)
    },
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    }),
    ...mapMutations({
      updatePropertyPromo: 'detailViews/updatePropertyPromo'
    })
  },
  computed: {
    additionalViewsPrice () {
      if (!this.additionalViewsForm.views) {
        return '-'
      }
      return this.additionalViewsForm.views * this.globalAdvertisingSettings.promoViewPrice
    },
    ...mapGetters({
      globalAdvertisingSettings: 'detailViews/globalAdvertisingSettings',
      property: 'detailViews/property'
    })
  },
  created () {
    this.form = { ...this.promo }
  }
}
</script>

<style scoped>

</style>
