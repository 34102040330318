<template>
  <div>
    <v-card>
      <v-card-text>
        Karta objektu byla vytvořena a poslána ke schválení
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="primary"
          @click="goToStep(1)"
        >
          Vytvořit další kartu
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Step8',
  computed: {
    ...mapGetters({
      step: 'propertyCard/formStep'
    })
  },
  methods: {
    ...mapMutations({
      goToStep: 'propertyCard/goToStep',
      resetForm: 'propertyCard/reset'
    })
  },
  watch: {
    step (val) {
      if (val === 8) {
        this.resetForm()
        this.$forceUpdate()
      }
    }
  }
}
</script>

<style scoped>

</style>
