<template>
  <v-container>
    <h1 id="price-header">Výchozí cena za noc: <span>{{ editedRoom.price || '-' }} Kč</span></h1>
    <div class="custom-heading">Nastavení ceny</div>
    <v-select
      v-model="editedRoom"
      :items="property.rooms"
      return-object
      item-text="name"
      label="Vyberte pokoj"
    ></v-select>
    <template v-if="!editedRoom.id">
      Prosím vyberte pokoj
    </template>
    <template v-else-if="!loadingRoom">
      <PropertyPriceSettingsForm
        @data-saved="getPrices"
      />
      <h2>Uložená data</h2>
      <v-data-table
        :headers="headers"
        dense
        :loading="fetchingPrices"
        :items="prices"
        item-key="idCode"
        :footer-props="{'items-per-page-options': [10, 20, 50] }"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            class="mr-2"
            small
            @click="deletePrice(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-dialog v-model="deleteDialog">
        <DeleteResource
          v-if="deleteDialog"
          :resource="priceToDelete"
          :resource-type="priceToDeleteType"
          @delete-success="showDeleteSuccess(); getPrices()"
          @delete-failure="showDeleteFailure()"
        />
      </v-dialog>
    </template>
    <v-skeleton-loader v-else type="article"/>
  </v-container>
</template>

<script>
import PropertyPriceSettingsForm from './PropertyPriceSettingsForm'
import { mapGetters } from 'vuex'
import DeleteResource from '@/components/DeleteResource'
import archiveHelpers from '@/mixins/local/archiveHelpers'

export default {
  name: 'PropertyPriceSettings',
  components: {
    DeleteResource,
    PropertyPriceSettingsForm
  },
  mixins: [archiveHelpers],
  data () {
    return {
      headers: [
        {
          text: 'Datum rozpětí',
          value: 'dateSpan'
        },
        {
          text: 'Opakovací',
          value: 'repeating'
        },
        { text: 'Platnost' },
        {
          text: 'Cena',
          value: 'dayPrice'
        },
        {
          text: 'Akce',
          value: 'actions',
          sortable: 'false'
        }
      ],
      prices: [],
      fetchingPrices: false,
      loadingRoom: false,
      days: [ // js nema setDay metodu
        {
          text: 'pondělí',
          value: 1
        },
        {
          text: 'úterý',
          value: 2
        },
        {
          text: 'středa',
          value: 3
        },
        {
          text: 'čtvrtek',
          value: 4
        },
        {
          text: 'pátek',
          value: 5
        },
        {
          text: 'sobota',
          value: 6
        },
        {
          text: 'neděle',
          value: 0
        }
      ],
      priceToDeleteType: null,
      priceToDelete: {}
    }
  },
  methods: {
    loadPrices () {},
    getPrices () {
      this.fetchingPrices = true
      this.loadingRoom = false
      this.$http.get(`/admin/rooms/${this.editedRoom.id}/prices`)
        .then(res => {
          const prices = res.data.prices.map(price => {
            const from = new Date(price.dateFrom)
            const to = new Date(price.dateTo)
            return {
              id: price.id,
              dayPrice: price.dayPrice,
              dateSpan: `${from.toLocaleDateString('cs-CZ')} - ${to.toLocaleDateString('cs-CZ')}`,
              repeating: 'Ne',
              // klic pro datatable
              idCode: `${price.id}price`
            }
          })

          const repeatingPrices = res.data.repeatingPrices.map(price => {
            const priceDays = []
            JSON.parse(price.days).forEach(day => {
              priceDays.push(this.days.find(d => d.value === day).text)
            })
            const priceMonths = []
            JSON.parse(price.months).forEach(month => {
              const dateTmp = new Date()
              dateTmp.setMonth(month - 1)
              priceMonths.push(dateTmp.toLocaleString('cs-CZ', { month: 'long' }))
            })

            price.dateSpan = priceDays.join(', ') + '; ' +
              priceMonths.join(', ')
            price.repeating = 'Ano'
            // klic pro datatable
            price.idCode = `${price.id}repeating-price`
            return price
          })

          this.prices = [].concat(prices, repeatingPrices)
        })
        .finally(() => {
          this.fetchingPrices = false
        })
    },
    deletePrice (price) {
      if (price.dateSpan) {
        this.priceToDeleteType = `admin/rooms/${this.editedRoom.id}/prices`
      } else {
        this.priceToDeleteType = `admin/rooms/${this.editedRoom.id}/repeating-prices`
      }
      this.priceToDelete = price
      this.deleteDialog = true
    }
  },
  computed: {
    editedRoom: {
      get () {
        return this.$store.state.priceSettings.editedRoom
      },
      set (val) {
        this.$store.state.priceSettings.editedRoom = val
      }
    },
    ...mapGetters({
      property: 'detailViews/property'
    })
  },
  created () {
    this.loadPrices = this.debounce(this.getPrices, 2000)
  },
  watch: {
    editedRoom: {
      deep: true,
      handler () {
        this.loadingRoom = true
        this.loadPrices()
      }
    }
  }
}
</script>

<style scoped>
#price-header {
  color: #919191;
  font-style: italic;
  text-align: right;
}
</style>
