<template>
  <div>
    <v-container
      class="mb-12"
    >
      <v-form v-model="formValid" ref="checkForm">
        <v-row>
          <v-col>
            <h2 class="display-1">Check-in Od</h2>
            <v-menu
              ref="checkInFromMenu"
              v-model="checkInFromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="property.checkInFrom"
              max-width="290px"
              min-width="290px"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="property.checkInFrom"
                  v-bind="attrs"
                  v-on="on"
                  :rules="[validation.required]"
                  class="rounded-0"
                  label="Check-in od"
                  outlined
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="checkInFromMenu"
                v-model="property.checkInFrom"
                format="24hr"
                full-width
                @click:minute="$refs.checkInFromMenu.save(property.checkInFrom)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col>
            <h2 class="display-1">Do</h2>
            <v-menu
              ref="checkInToMenu"
              v-model="checkInToMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="property.checkInTo"
              max-width="290px"
              min-width="290px"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="property.checkInTo"
                  v-bind="attrs"
                  v-on="on"
                  :rules="[validation.required]"
                  class="rounded-0"
                  label="Check-in do"
                  outlined
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="checkInToMenu"
                v-model="property.checkInTo"
                format="24hr"
                full-width
                @click:minute="$refs.checkInToMenu.save(property.checkInTo)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col>
            <h2 class="display-1">Check-out od</h2>
            <v-menu
              ref="checkOutFromMenu"
              v-model="checkOutFromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="property.checkOutFrom"
              max-width="290px"
              min-width="290px"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="property.checkOutFrom"
                  v-bind="attrs"
                  v-on="on"
                  :rules="[validation.required]"
                  class="rounded-0"
                  label="Check-out od"
                  outlined
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="checkOutFromMenu"
                v-model="property.checkOutFrom"
                format="24hr"
                full-width
                @click:minute="$refs.checkOutFromMenu.save(property.checkOutFrom)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col>
            <h2 class="display-1">Do</h2>
            <v-menu
              ref="checkOutToMenu"
              v-model="checkOutToMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="property.checkOutTo"
              max-width="290px"
              min-width="290px"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="property.checkOutTo"
                  v-bind="attrs"
                  v-on="on"
                  :rules="[validation.required]"
                  class="rounded-0"
                  label="Check-out do"
                  outlined
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="checkOutToMenu"
                v-model="property.checkOutTo"
                format="24hr"
                full-width
                @click:minute="$refs.checkOutToMenu.save(property.checkOutTo)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-btn
      :disabled="!formValid"
      color="primary"
      @click="save"
      :loading="savingData"
    >
      Dokončit
    </v-btn>

    <v-btn text
           @click="goToStep(6)"
    >
      Předchozí krok
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { EventBus } from '@/events/event_bus'
export default {
  name: 'Step7',
  data () {
    return {
      checkOutToMenu: false,
      checkOutFromMenu: false,
      checkInToMenu: false,
      checkInFromMenu: false,
      formValid: true,
      savingData: false
    }
  },
  methods: {
    save () {
      this.savingData = true
      const propertyPromise = this.$http({
        method: this.property.id ? 'PUT' : 'POST',
        url: this.property.id ? `/properties/${this.property.id}` : '/properties',
        data: this.formattedProperty
      })

      propertyPromise
        .then(res => {
          const photoData = new FormData()

          if (this.mainPhoto) {
            photoData.append('main', this.mainPhoto.photo)
          }

          if (this.otherPhotos.length) {
            this.otherPhotos.forEach((photoWrapper, index) => {
              photoData.append('other[]', photoWrapper.photo)
            })
          } else {
            photoData.append('other', [])
          }

          /* for (const pair of photoData.entries()) {
            console.log(pair[0] + ', ' + pair[1])
          }
          console.log(photoData.has('main')) */
          this.$http.post(`/properties/${res.data.id}/photos/upload`,
            photoData,
            {
              headers: {
                'Content-Type': `multipart/form-data;boundary=${Math.random().toString().substr(2)}`
              }
            })
            .then(res => {
            }).catch(() => {
              this.setAlerts([{
                type: 'error',
                message: 'Při náhrávání fotek došlo k chybě'
              }])
            })
            .finally(() => {
              this.savingData = false
              EventBus.$emit('reset-forms')
              this.goToStep(8)
            })
        })
    },
    ...mapMutations({
      goToStep: 'propertyCard/goToStep'
    }),
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  computed: {
    property: {
      get () {
        return this.$store.state.propertyCard.property
      },
      set (val) {
        this.$store.state.propertyCard.property = val
      }
    },
    ...mapGetters({
      formattedProperty: 'propertyCard/formattedProperty',
      mainPhoto: 'propertyCardPhotos/mainPhoto',
      otherPhotos: 'propertyCardPhotos/otherPhotos'
    })
  },
  created () {
    EventBus.$on('reset-forms', () => {
      this.$refs.checkForm.reset()
    })
  }
}
</script>

<style scoped>

</style>
