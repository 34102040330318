<template>
  <v-card>
    <v-card-text>
      <h3>Rozsah</h3>
      {{ (new Date(advertising.dateFrom)).toLocaleDateString() }} -
      {{ (new Date(advertising.dateTo)).toLocaleDateString() }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AdvertisingHistoryCard',
  props: {
    advertising: {
      required: true,
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
