<template>
  <v-autocomplete
    @blur="active = false"
    @focus="active = true"
    @input="active = false"
    ref="locationInput"
    v-model="location"
    :filter="() => true"
    :items="locationObjects"
    :loading="loading"
    :rules="[validation.required]"
    :search-input.sync="search"
    class="rounded-0"
    hide-details
    item-text="displayName"
    return-object
    label="Kam to bude"
    outlined
  >
    <template v-slot:append>
      <v-icon
        v-if="!loading"
        @click="$refs.locationInput.reset()"
      >
        mdi-close
      </v-icon>
      <v-progress-circular
        v-else
        color="primary"
        indeterminate
      >
      </v-progress-circular>
      <v-icon
        style="cursor: pointer"
      >
        mdi-{{active ? 'menu-up' : 'menu-down'}}
      </v-icon>
    </template>
  </v-autocomplete>
</template>

<script>

import { EventBus } from '@/events/event_bus'

export default {
  name: 'NominatimSearch',
  props: {
    value: {
      required: true
    },
    searchText: {
      required: false,
      type: String
    }
  },
  data () {
    return {
      search: '',
      location: {},
      locationObjects: [],
      nominatimUrl: process.env.VUE_APP_NOMINATIM_URL,
      loading: false,
      active: false
    }
  },
  watch: {
    search (val) {
      val && this.loadLocationObjects()
    },
    location (val) {
      this.$emit('input', val)
    }
  },
  methods: {
    loadLocationObjects () {
    },
    getLocationObjects () {
      if (this.search && this.search.length !== 0) {
        this.loading = true
        this.$http.get(`${this.nominatimUrl}/search?addressdetails=1&q=${this.search}%20&format=jsonv2`)
          .then(res => {
            this.locationObjects = res.data
            this.loading = false
          }).catch(err => console.log(err))
      }
    },
    filter (item, queryText, itemText) {
      return true

      // :) necham si to tady na horsi casy
      /* const queryTextArr = queryText.toLowerCase()
        // odstraneni diakritiky
        .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        // rozdeleni podle white-space a carek
        .split(/[\s,]+/)
      const itemTextArr = itemText.toLowerCase()
        .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        // rozdeleni podle white-space a carek
        .split(/[\s,]+/)

      return queryTextArr.some(part => itemTextArr.includes(part)) */
    }
  },
  mounted () {
    this.search = null
    // tady skakalo Rusko :)
    if (this.value && this.value.displayName) {
      this.loading = true
      this.$http.get(`${this.nominatimUrl}/search?addressdetails=1&q=${this.value.displayName}%20&format=jsonv2`)
        .then(res => {
          this.loading = false
          this.locationObjects = res.data
          this.location = res.data[0]
          this.$forceUpdate()
          EventBus.$emit('objects-loaded')
        })
    }
    this.loadLocationObjects = this.debounce(this.getLocationObjects, 2000)
  }
}
</script>

<style scoped>

</style>
