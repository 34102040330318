var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"mb-12"},[_c('v-form',{ref:"checkForm",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-col',[_c('h2',{staticClass:"display-1"},[_vm._v("Check-in Od")]),_c('v-menu',{ref:"checkInFromMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.property.checkInFrom,"max-width":"290px","min-width":"290px","offset-y":"","transition":"scale-transition"},on:{"update:returnValue":function($event){return _vm.$set(_vm.property, "checkInFrom", $event)},"update:return-value":function($event){return _vm.$set(_vm.property, "checkInFrom", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-0",attrs:{"rules":[_vm.validation.required],"label":"Check-in od","outlined":"","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.property.checkInFrom),callback:function ($$v) {_vm.$set(_vm.property, "checkInFrom", $$v)},expression:"property.checkInFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.checkInFromMenu),callback:function ($$v) {_vm.checkInFromMenu=$$v},expression:"checkInFromMenu"}},[(_vm.checkInFromMenu)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.checkInFromMenu.save(_vm.property.checkInFrom)}},model:{value:(_vm.property.checkInFrom),callback:function ($$v) {_vm.$set(_vm.property, "checkInFrom", $$v)},expression:"property.checkInFrom"}}):_vm._e()],1)],1),_c('v-col',[_c('h2',{staticClass:"display-1"},[_vm._v("Do")]),_c('v-menu',{ref:"checkInToMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.property.checkInTo,"max-width":"290px","min-width":"290px","offset-y":"","transition":"scale-transition"},on:{"update:returnValue":function($event){return _vm.$set(_vm.property, "checkInTo", $event)},"update:return-value":function($event){return _vm.$set(_vm.property, "checkInTo", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-0",attrs:{"rules":[_vm.validation.required],"label":"Check-in do","outlined":"","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.property.checkInTo),callback:function ($$v) {_vm.$set(_vm.property, "checkInTo", $$v)},expression:"property.checkInTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.checkInToMenu),callback:function ($$v) {_vm.checkInToMenu=$$v},expression:"checkInToMenu"}},[(_vm.checkInToMenu)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.checkInToMenu.save(_vm.property.checkInTo)}},model:{value:(_vm.property.checkInTo),callback:function ($$v) {_vm.$set(_vm.property, "checkInTo", $$v)},expression:"property.checkInTo"}}):_vm._e()],1)],1),_c('v-col',[_c('h2',{staticClass:"display-1"},[_vm._v("Check-out od")]),_c('v-menu',{ref:"checkOutFromMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.property.checkOutFrom,"max-width":"290px","min-width":"290px","offset-y":"","transition":"scale-transition"},on:{"update:returnValue":function($event){return _vm.$set(_vm.property, "checkOutFrom", $event)},"update:return-value":function($event){return _vm.$set(_vm.property, "checkOutFrom", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-0",attrs:{"rules":[_vm.validation.required],"label":"Check-out od","outlined":"","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.property.checkOutFrom),callback:function ($$v) {_vm.$set(_vm.property, "checkOutFrom", $$v)},expression:"property.checkOutFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.checkOutFromMenu),callback:function ($$v) {_vm.checkOutFromMenu=$$v},expression:"checkOutFromMenu"}},[(_vm.checkOutFromMenu)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.checkOutFromMenu.save(_vm.property.checkOutFrom)}},model:{value:(_vm.property.checkOutFrom),callback:function ($$v) {_vm.$set(_vm.property, "checkOutFrom", $$v)},expression:"property.checkOutFrom"}}):_vm._e()],1)],1),_c('v-col',[_c('h2',{staticClass:"display-1"},[_vm._v("Do")]),_c('v-menu',{ref:"checkOutToMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.property.checkOutTo,"max-width":"290px","min-width":"290px","offset-y":"","transition":"scale-transition"},on:{"update:returnValue":function($event){return _vm.$set(_vm.property, "checkOutTo", $event)},"update:return-value":function($event){return _vm.$set(_vm.property, "checkOutTo", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-0",attrs:{"rules":[_vm.validation.required],"label":"Check-out do","outlined":"","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.property.checkOutTo),callback:function ($$v) {_vm.$set(_vm.property, "checkOutTo", $$v)},expression:"property.checkOutTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.checkOutToMenu),callback:function ($$v) {_vm.checkOutToMenu=$$v},expression:"checkOutToMenu"}},[(_vm.checkOutToMenu)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.checkOutToMenu.save(_vm.property.checkOutTo)}},model:{value:(_vm.property.checkOutTo),callback:function ($$v) {_vm.$set(_vm.property, "checkOutTo", $$v)},expression:"property.checkOutTo"}}):_vm._e()],1)],1)],1)],1)],1),_c('v-btn',{attrs:{"disabled":!_vm.formValid,"color":"primary","loading":_vm.savingData},on:{"click":_vm.save}},[_vm._v(" Dokončit ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.goToStep(6)}}},[_vm._v(" Předchozí krok ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }