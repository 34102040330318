<template>
  <div>
    <v-dialog
      v-model="showPhotoDialog"
      width="500"
    >
      <ShowPhotoCard
        :photo-url="photoPreviewUrl"
        @photo-closed="showPhotoDialog = false"
      />
    </v-dialog>
    <v-card
      class="mb-12"
    >
      <v-data-table
        :headers="headers"
        :items="photos"
        calculate-widths
        class="text-right"
        :footer-props="{'items-per-page-options': [10, 20, 50] }"
      >
        <template v-slot:top>
          <v-form v-model="photoToAddValid" ref="addPhotoForm">
            <v-toolbar
              flat
            >
              <v-toolbar-title>Fotky</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-file-input
                color="primary"
                outlined
                ref="photoInput"
                v-model="photoToAdd"
                :rules="[validation.fileTypes(['png', 'jpg', 'jpeg', 'gif'])]"
                class="rounded-0"
                label="Přidat fotku"
                hint="Podporovaný formát .png, .jpg, .jpeg, .gif"
                dense
              >
              </v-file-input>
              <v-btn
                v-if="photoToAdd"
                @click="addPhoto"
                :disabled="!photoToAddValid"
              >Přidat</v-btn>
            </v-toolbar>
          </v-form>
        </template>
        <template v-slot:item.main="{ item }">
          <v-icon v-if="item.main">
            mdi-star
          </v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            small
            class="mr-2"
            @click="setAsMainPhoto(item)"
            color="orange"
            v-if="!item.main"
          >
            Nastavit jako hlavní
          </v-btn>
          <v-btn
            small
            class="mr-2"
            @click="showPhoto(item)"
            color="primary"
          >
            Zobrazit
          </v-btn>
          <v-btn
            small
            class="mr-2"
            @click="deletePhoto(item)"
            color="red"
          >
            Smazat
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-btn
      color="primary"
      @click="goToStep(7)"
    >
      Pokračovat
    </v-btn>

    <v-btn text
           @click="goToStep(3)"
    >
      Předchozí krok
    </v-btn>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import ShowPhotoCard from '@/components/property/createPropertySteps/ShowPhotoCard'
import { EventBus } from '@/events/event_bus'

export default {
  name: 'Step6',
  components: { ShowPhotoCard },
  data () {
    return {
      photoToAddValid: true,
      showPhotoDialog: false,
      photoPreviewUrl: null,
      headers: [
        {
          text: '',
          value: 'main'
        },
        {
          text: 'Název',
          align: 'start',
          sortable: false,
          value: 'photo.name'
        },
        { text: 'Velikost', value: 'photo.size' },
        { text: 'Akce', value: 'actions', sortable: false }
      ],
      photoToAdd: null
    }
  },
  methods: {
    addPhoto () {
      this.addPhotoToStore(this.photoToAdd)
      this.$refs.photoInput.reset()
    },
    showPhoto (photo) {
      this.photoPreviewUrl = photo.previewPhotoUrl
      this.showPhotoDialog = true
    },
    ...mapMutations({
      goToStep: 'propertyCard/goToStep',
      setAsMainPhoto: 'propertyCardPhotos/setAsMainPhoto',
      deletePhoto: 'propertyCardPhotos/deletePhoto',
      addPhotoToStore: 'propertyCardPhotos/addPhoto',
      resetPhotos: 'propertyCardPhotos/reset'
    })
  },
  computed: {
    photos: {
      get () {
        return this.$store.state.propertyCardPhotos.photos
      },
      set (val) {
        this.$store.state.propertyCardPhotos.photos = val
      }
    }
  },
  created () {
    EventBus.$on('reset-forms', () => {
      this.resetPhotos()
      this.$refs.addPhotoForm.reset()
    })
  }
}
</script>

<style scoped>
>>>td:last-child{
  width: 40%;
}
</style>
