<template>
  <v-container>
    <AdvertisingForm />
    <!-- historie ??? -->
    <AdvertisingHistoryCard
      v-for="ad in property.advertising" :key="ad.id"
      :advertising="ad"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import AdvertisingForm from '@/components/advertising/AdvertisingForm'
import AdvertisingHistoryCard from '@/components/advertising/AdvertisingHistoryCard'

export default {
  name: 'AdvertisingSettings',
  components: { AdvertisingHistoryCard, AdvertisingForm },
  computed: {
    ...mapGetters({
      property: 'detailViews/property'
    })
  }
}
</script>

<style scoped>

</style>
