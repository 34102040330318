<template>
  <v-form v-model="formValid" ref="priceForm">
    <v-row>
      <v-col>
        <v-text-field
          label="Cena za noc"
          type="number"
          min="0"
          outlined
          class="rounded-0"
          dense
          v-model.number="price"
          :rules="[validation.required]"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-checkbox
          label="Opakovací"
          v-model="repeating"
          dense>
        </v-checkbox>
      </v-col>
      <v-col
        v-if="repeating">
        <v-select
          label="Vyberte dny"
          ref="repeatingDaysInput"
          dense
          small-chips
          hide-selected
          deletable-chips
          outlined
          v-model="repeatingPrice.days"
          multiple
          :rules="[validation.required]"
          :items="days"
          class="rounded-0"
        ></v-select>
      </v-col>
      <v-col
        v-if="repeating">
        <v-select
          label="Vyberte měsíce"
          ref="repeatingMonthsInput"
          dense
          small-chips
          hide-selected
          deletable-chips
          outlined
          v-model="repeatingPrice.months"
          multiple
          :rules="[validation.required]"
          :items="months"
          class="rounded-0"
        ></v-select>
      </v-col>
      <v-col
        v-if="!repeating">
        <vc-date-picker
          v-model="fixedPrice"
          is-range
          is-required
          :columns="2"
          color="blue"
          locale="cs-CZ"
          :min-date="new Date()"
          :timezone="''"
          :popover="{ visibility: 'focus' }">
          <template v-slot="{ fixedPrice: inputValue, inputEvents }">
            <div class="d-flex justify-center items-center">
              <v-text-field
                outlined
                hide-details
                readonly
                dense
                :rules="[validation.required]"
                :value="getLocalizedDate(fixedPrice.start)"
                v-on="inputEvents.start"
                placeholder="Od"
                class="rounded-0 border-right-none"
                prepend-inner-icon="mdi-calendar"
              />
              <v-icon color="" style="border-radius: 0px; border-top: 1px rgba(0, 0, 0, 0.38) solid; border-bottom: 1px rgba(0, 0, 0, 0.38) solid;padding: 7px 0 7px 0; background-color: white">mdi-arrow-right-thick</v-icon>
              <v-text-field
                class="rounded-0 border-left-none"
                outlined
                :rules="[validation.required]"
                dense
                :value="getLocalizedDate(fixedPrice.end)"
                v-on="inputEvents.end"
                hide-details
                readonly
                placeholder="Do"
              />
            </div>
          </template>
        </vc-date-picker>
      </v-col>
      <v-col>
        <v-btn
          color="primary"
          :disabled="!formValid || savingRequest"
          class="rounded-0"
          @click="save"
        >
          Uložit
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PropertyPriceSettingsForm',
  data () {
    return {
      savingRequest: false,
      formValid: true,
      repeating: false,
      days: [ // js nema setDay metodu
        { text: 'pondělí', value: 1 },
        { text: 'úterý', value: 2 },
        { text: 'středa', value: 3 },
        { text: 'čtvrtek', value: 4 },
        { text: 'pátek', value: 5 },
        { text: 'sobota', value: 6 },
        { text: 'neděle', value: 0 }
      ],
      months: [],
      // price v-models
      price: null,
      repeatingPrice: {
        months: null,
        days: null
      },
      fixedPrice: {
        start: null,
        end: null
      }
    }
  },
  methods: {
    save () {
      this.savingRequest = true
      let priceType = ''
      let requestBody = {}

      if (this.repeating) {
        priceType = 'repeating-prices'
        requestBody = {
          price: this.price,
          months: this.repeatingPrice.months.sort(),
          days: this.repeatingPrice.days.sort()
        }
      } else {
        priceType = 'prices'
        requestBody = {
          price: this.price,
          dateFrom: this.extractDate(this.fixedPrice.start),
          dateTo: this.extractDate(this.fixedPrice.end)
        }
      }
      this.$http.post(`/admin/rooms/${this.editedRoom.id}/${priceType}`, requestBody)
        .then(res => {
          this.setAlerts([{ message: 'Data uložena', type: 'success' }])
          this.$refs.priceForm.reset()
          this.$emit('data-saved')
        })
        .finally(() => {
          this.savingRequest = false
        })
    },
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  computed: {
    ...mapGetters({
      editedRoom: 'priceSettings/editedRoom'
    })
  },
  watch: {
    repeating (val) {
      if (val) {
        // TODO: reset vc-date-picker
      } else {
        this.$refs.repeatingDaysInput.reset()
        this.$refs.repeatingMonthsInput.reset()
      }
    }
  },
  created () {
    // init months options in select
    for (let i = 0; i < 12; i++) {
      const date = new Date()
      date.setMonth(i)

      this.months.push({
        value: date.getMonth() + 1,
        text: date.toLocaleString('cs-CZ', { month: 'long' })
      })
    }
  }
}
</script>

<style scoped>

</style>
