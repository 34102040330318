var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" Recenze "),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.reviews,"loading":_vm.runningRequest,"server-items-length":_vm.reviewsTotal,"options":_vm.paginationOptions,"footer-props":{'items-per-page-options': [10, 20, 50] }},on:{"update:options":function($event){_vm.paginationOptions=$event}},scopedSlots:_vm._u([{key:"item.propertyName",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"table-link",attrs:{"href":("/admin/properties/" + (item.property.id))}},[_vm._v(" "+_vm._s(item.property.name)+" ")])]}},{key:"item.demandId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.demand.id)+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getLocalizedDate(item.createdAt))+" ")]}},{key:"item.approved",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getReviewState(item).color}},[_vm._v(" "+_vm._s(_vm.getReviewState(item).text)+" ")])]}},{key:"item.finalRating",fn:function(ref){
var item = ref.item;
return [_c('v-rating',{attrs:{"half-increments":"","dense":"","length":"5","size":"18","readonly":"","value":item.finalRating,"color":"orange"}})]}},(_vm.user.roleId === 1 || _vm.user.roleId === 2)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.approvalProcessState === 1)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.approveReview(item)}}},[_vm._v(" mdi-check ")]):_vm._e(),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editReview(item)}}},[_vm._v(" mdi-information-variant ")]),(item.approvalProcessState !== 4)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteReview(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}:null],null,true)})],1),_c('v-dialog',{attrs:{"width":"1050"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Změnit stav")]),_c('v-card-text',{staticClass:"pb-0"},[(_vm.editDialog)?_c('AdminReviewForm',{attrs:{"review":_vm.reviewToEdit},on:{"edit-success":_vm.showEditSuccess,"edit-failure":function($event){_vm.editDialog = false}}}):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"width":"350"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[(_vm.deleteDialog)?_c('DeleteResource',{attrs:{"resource-type":"admin/reviews","resource":_vm.reviewToDelete},on:{"delete-success":_vm.deleteSuccess,"delete-failure":function($event){_vm.deleteDialog = false}}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"350"},model:{value:(_vm.approveDialog),callback:function ($$v) {_vm.approveDialog=$$v},expression:"approveDialog"}},[(_vm.approveDialog)?_c('ApproveReviewCard',{attrs:{"review":_vm.reviewToApprove},on:{"edit-success":_vm.showApprovalSuccess,"edit-failure":function($event){_vm.approveDialog = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }