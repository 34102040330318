<template>
  <div>
    <v-card>
      <v-card-title>
        Recenze
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="reviews"
        class="elevation-1"
        :loading="runningRequest"
        :server-items-length="reviewsTotal"
        :options.sync="paginationOptions"
        :footer-props="{'items-per-page-options': [10, 20, 50] }"
      >
        <template v-slot:item.propertyName="{ item }">
          <a :href="`/admin/properties/${item.property.id}`" class="table-link">
            {{ item.property.name }}
          </a>
        </template>
        <template v-slot:item.demandId="{ item }">
            {{ item.demand.id }}
        </template>

        <template v-slot:item.createdAt="{ item }">
          {{ getLocalizedDate(item.createdAt) }}
        </template>
        <template v-slot:item.approved="{ item }">
          <v-chip :color="getReviewState(item).color">
            {{ getReviewState(item).text }}
          </v-chip>
        </template>
        <template v-slot:item.finalRating="{ item }">
          <v-rating
            half-increments
            dense
            length="5"
            size="18"
            readonly
            :value="item.finalRating"
            color="orange"
          >
          </v-rating>
        </template>
        <template v-slot:item.actions="{ item }" v-if="user.roleId === 1 || user.roleId === 2">
          <v-icon
            small
            @click="approveReview(item)"
            v-if="item.approvalProcessState === 1"
            class="mr-2"
          >
            mdi-check
          </v-icon>
          <v-icon
            small
            class="mr-2"
            @click="editReview(item)"
          >
            mdi-information-variant
          </v-icon>
          <v-icon
            small
            v-if="item.approvalProcessState !== 4"
            @click="deleteReview(item)"
            class="mr-2"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      width="1050"
      v-model="editDialog">
      <v-card>
        <v-card-title>Změnit stav</v-card-title>
        <v-card-text class="pb-0">
          <AdminReviewForm
            @edit-success="showEditSuccess"
            @edit-failure="editDialog = false"
            :review="reviewToEdit"
            v-if="editDialog"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      width="350"
      v-model="deleteDialog">
      <DeleteResource
        resource-type="admin/reviews"
        :resource="reviewToDelete"
        @delete-success="deleteSuccess"
        @delete-failure="deleteDialog = false"
        v-if="deleteDialog"
      />
    </v-dialog>
    <v-dialog
      width="350"
      v-model="approveDialog">
      <ApproveReviewCard
        v-if="approveDialog"
        :review="reviewToApprove"
        @edit-success="showApprovalSuccess"
        @edit-failure="approveDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ApproveReviewCard from '@/components/reviews/ApproveReviewCard'
import DeleteResource from '@/components/DeleteResource'
import archiveHelpers from '@/mixins/local/archiveHelpers'
import AdminReviewForm from '@/components/reviews/AdminReviewForm'
export default {
  name: 'ReviewsOverview',
  props: {
    type: { // user/property - meni se url a jestli lognutej user muze ty recenze mazat/editovat
      required: true,
      type: String
    }
  },
  components: {
    AdminReviewForm,
    DeleteResource,
    ApproveReviewCard
  },
  mixins: [archiveHelpers],
  data () {
    return {
      approveDialog: false,
      editDialog: false,
      headers: [
        {
          text: 'Číslo rezervace',
          value: 'demandId'
        },
        {
          text: 'Objekt',
          value: 'propertyName'
        },
        {
          text: 'Vytvořeno',
          value: 'createdAt'
        },
        { text: 'Hodnocení', value: 'finalRating' },
        { text: 'Status', value: 'approved' },
        { text: 'Akce', value: 'actions', sortable: false }
      ],
      reviews: [],
      reviewsTotal: null,
      paginationOptions: {
        itemsPerPage: 10,
        page: 1,
        sortDesc: [],
        sortBy: []
      },
      reviewToApprove: {},
      reviewToDelete: {},
      reviewToEdit: {},
      runningRequest: false
    }
  },
  watch: {
    paginationOptions: {
      deep: true,
      handler () {
        this.loadReviews()
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'userAuth/user',
      property: 'detailViews/property'
    })
  },
  methods: {
    loadReviews () {},
    getReviews () {
      this.runningRequest = true
      const {
        page,
        sortBy,
        sortDesc,
        itemsPerPage
      } = this.paginationOptions

      const url = this.type === 'user' ? `/users/${this.user.id}/reviews` : `/admin/properties/${this.property.id}/reviews`

      this.$http.get(url, {
        params: {
          page,
          itemsPerPage,
          sortBy: sortBy.length ? sortBy[0] : null,
          sortDesc: sortDesc.length ? sortDesc[0] : null
        }
      })
        .then(res => {
          this.reviewsTotal = +res.data.total
          this.reviews = res.data.reviews
        })
        .finally(() => {
          this.runningRequest = false
        })
    },
    editReview (item) {
      this.reviewToEdit = { ...item }
      this.editDialog = true
    },
    approveReview (item) {
      this.reviewToApprove = { ...item }
      this.approveDialog = true
    },
    deleteReview (item) {
      this.deleteDialog = true
      this.reviewToDelete = { ...item }
    },
    deleteSuccess () {
      this.showDeleteSuccess()
      this.getReviews()
    },
    showApprovalSuccess () {
      this.approveDialog = false
      this.setAlerts([{
        message: 'Recenze schválena',
        type: 'success'
      }])
      this.getReviews()
    },
    showEditSuccess () {
      this.editDialog = false
      this.setAlerts([{
        message: 'Editace proběhla úspěšně',
        type: 'success'
      }])
      this.getReviews()
    },
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    }),
    getReviewState (review) {
      if (review.approvalProcessState === 1) {
        return {
          text: 'Čekající',
          color: 'orange'
        }
      }
      if (review.approvalProcessState === 2) {
        return {
          text: 'Neschválená',
          color: 'red'
        }
      }
      if (review.approvalProcessState === 3) {
        return {
          text: 'Schválená',
          color: 'green'
        }
      }
      return {
        text: 'Smazaná',
        color: 'red'
      }
    }
  },
  mounted () {
    if (this.type === 'property') {
      // kdyz je ta tabulka u objektu, nema smysl mit sloupec s nazvem objektu (a jeste podle nej sortovat :) )
      this.headers.splice(1, 1)
    }
    this.getReviews()
    this.loadReviews = this.debounce(this.getReviews, 2000)
  }
}
</script>

<style scoped>

</style>
