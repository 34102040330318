<template>
  <div>
    <v-container
      class="mb-12"
    >
      <v-form ref="equipmentForm">
        <v-row class="mt-1">
          <v-col>
            <v-text-field
              v-model.number="equipment[3]"
              type="number"
              label="Kuchyně"
              outlined
              @change="$forceUpdate()"
              min="0"
              hide-details
              class="rounded-0">
            </v-text-field>
            <v-checkbox
              v-if="equipment[3] > 0"
              v-model="equipment[4]"
              label="Vlastní kuchyně">
            </v-checkbox>
          </v-col>
          <v-col>
            <v-text-field
              v-model.number="equipment[1]"
              type="number"
              label="Koupelna"
              @change="$forceUpdate()"
              outlined
              min="0"
              hide-details
              class="rounded-0">
            </v-text-field>
            <v-checkbox
              v-if="equipment[1] > 0"
              v-model="equipment[2]"
              label="Vlastní koupelna">
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"><div class="display-1">Ostatní vybavení</div></v-col>
          <v-col>
            <v-checkbox
              v-for="equipmentType in equipmentTypes[1]"
              :key="equipmentType.id"
              dense
              v-model="equipment[equipmentType.id]"
              hide-details
              :label="equipmentType.name">
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="display-1">Nepovinné parametry</div>
            <br>
            <p>Umístění objektu</p>
          </v-col>
        </v-row>
          <v-row
            v-for="equipmentType in equipmentTypes[2]"
            :key="equipmentType.id"
          >
            <v-col class="col-xs-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <v-slider
                v-model="equipment[equipmentType.id]"
                :max="2500"
                min="0"
                step="50"
                :disabled="equipment[equipmentType.id + '@the_place']"
                :label="`${equipmentType.name} ( m )`"
                class="align-center"
              >
                <template v-slot:append>
                  <v-text-field
                    v-model.trim="equipment[equipmentType.id]"
                    class="mt-0 pt-0"
                    single-line
                    type="number"
                    style="width: 60px"
                  ></v-text-field>
                </template>
              </v-slider>
            </v-col>
            <v-col class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <v-checkbox
                label="Přímo v místě"
                hide-details
                v-model="equipment[equipmentType.id + '@the_place']"
                @change="setDistanceInPlace(2, equipmentType.id, $event)"
                dense
              >
              </v-checkbox>
            </v-col>
          </v-row>
        <v-row>
          <v-col cols="12">
            <p>Služby hostitele</p>
          </v-col>
          <v-col cols="12">
            <v-select
              multiple
              label="Možnost stravování"
              :items="equipmentTypes[3]"
              hide-selected
              dense
              ref="eqType3Input"
              small-chips
              deletable-chips
              v-model="eqGroup3Tmp"
              item-text="name"
              item-value="id"
              @input="setEquipmentArray(3, $event)"
              class="rounded-0"
              outlined
            >
            </v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              outlined
              multiple
              item-text="name"
              item-value="id"
              ref="eqType5Input"
              hide-selected
              small-chips
              deletable-chips
              @input="setEquipmentArray(5, $event)"
              label="Možnosti zapůjčení vybavení"
              :items="equipmentTypes[5]"
              v-model="eqGroup5Tmp"
              dense
              class="rounded-0"
            >
            </v-select>
          </v-col>
          <v-col cols="12" v-for="equipmentType in equipmentTypes[4]" :key="equipmentType.id">
            <v-checkbox
              :label="equipmentType.name"
              v-model="equipment[equipmentType.id]"
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"><p>Služby v okolí</p></v-col>
        </v-row>
        <v-row v-for="equipmentType in equipmentTypes[6]" :key="equipmentType.id" >
          <v-col class="col-xs-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
            <v-slider
              v-model="equipment[equipmentType.id]"
              :max="2500"
              :disabled="equipment[equipmentType.id + '@the_place']"
              min="0"
              step="50"
              :label="`${equipmentType.name} ( m )`"
              class="align-center"
            >
              <template v-slot:append>
                <v-text-field
                  v-model.number="equipment[equipmentType.id]"
                  class="mt-0 pt-0"
                  single-line
                  type="number"
                  style="width: 60px"
                ></v-text-field>
              </template>
            </v-slider>
          </v-col>
          <v-col class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <v-checkbox
              label="Přímo v místě"
              hide-details
              dense
              v-model="equipment[equipmentType.id + '@the_place']"
              @change="setDistanceInPlace(6, equipmentType.id, $event)"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="display-1">Ostatní vybavení</div>
          </v-col><v-col cols="12" v-for="equipmentType in equipmentTypes[7]" :key="equipmentType.id">
          <v-checkbox
            :label="equipmentType.name"
            v-model="equipment[equipmentType.id]"
            dense
          />
        </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-btn
      color="primary"
      @click="save"
    >
      Uložit jednotku
    </v-btn>

    <v-btn
      text
      @click="goToStep(4)"
    >
      Předchozí krok
    </v-btn>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { EventBus } from '@/events/event_bus'

export default {
  name: 'Step5',
  data () {
    return {
      eqGroup3Tmp: [],
      eqGroup5Tmp: [],
      equipment: {},
      equipmentTypes: []
    }
  },
  methods: {
    /**
     * Funkce pro nastavovani vybranych hodnot z pole
     * @param {Number} type  3 - stravovani / 5 - zapujceni
     * @param {Number[]} values pole vybranych equipmentTypeId z v-selectu
     * @return void
     */
    setEquipmentArray (type, values) {
      // reset vsech hodnot
      this.equipmentTypes[type].forEach(equipment => {
        this.equipment[equipment.id] = null
      })

      // prepsani novych
      values.forEach(equipmentTypeId => {
        this.equipment[equipmentTypeId] = 1
      })
    },
    /**
     * Nastaveni 'primo v miste' checkboxu
     * @param {Number} type 2 - umisteni / 6 - sluzby v okoli
     */
    setDistanceInPlace (type, place, value) {
      if (value) {
        this.equipment[place] = 0
      }
      this.$forceUpdate()
    },
    save () {
      this.saveEquipment(this.equipment)
      this.saveEditedRoom()
      this.equipment = {}
      this.goToStep(3)
      this.$forceUpdate()
    },
    ...mapMutations({
      goToStep: 'propertyCard/goToStep',
      saveEditedRoom: 'propertyCard/saveEditedRoom',
      saveEquipment: 'propertyCard/saveEquipment'
    })
  },
  computed: {
    ...mapGetters({
      property: 'propertyCard/property',
      editedRoom: 'propertyCard/editedRoom',
      step: 'propertyCard/formStep'
    })
  },
  created () {
    this.equipment = {}
    for (let i = 0; i < 8; i++) {
      this.equipmentTypes.push([])
      // this.equipment.push({})
    }
    this.$http.get('/equipment-types')
      .then(res => {
        res.data.forEach(eqType => {
          this.equipmentTypes[eqType.equipmentGroup].push({ ...eqType })
          this.equipment[eqType.id] = null
          /* if (eqType.equipmentGroup === 3 || eqType.equipmentGroup === 5) {
            this.equipment[eqType.equipmentGroup] = []
          } else {
            this.equipment[eqType.equipmentGroup][eqType.id] = null
          } */

          // primo v miste checkboxy
          if (eqType.equipmentGroup === 6 || eqType.equipmentGroup === 2) {
            const keyName = eqType.id + '@the_place'
            this.equipment[keyName] = false
          }
        })
      })

    EventBus.$on('reset-forms', () => {
      this.$refs.equipmentForm.reset()
    })
  },
  watch: {
    step (val) {
      if (val === 5) {
        // premapovani obbjektu s indexy do pole pro stravovani a nabizene sluzby
        this.eqGroup3Tmp = []
        this.eqGroup5Tmp = []
        if (Object.keys(this.editedRoom.equipment).length) {
          // stravovani equipment type id
          const eqGroup3Ids = this.equipmentTypes[3].map(type => type.id)
          // vybaveni
          const eqGroup5Ids = this.equipmentTypes[5].map(type => type.id)
          Object.keys(this.editedRoom.equipment).forEach(equipmentTypeId => {
            if (eqGroup3Ids.includes(+equipmentTypeId)) {
              if (this.editedRoom.equipment[equipmentTypeId]) {
                this.eqGroup3Tmp.push(+equipmentTypeId)
              }
            }
            if (eqGroup5Ids.includes(+equipmentTypeId)) {
              if (this.editedRoom.equipment[equipmentTypeId]) {
                this.eqGroup5Tmp.push(+equipmentTypeId)
              }
            }
          })
          this.equipment = { ...this.editedRoom.equipment }
          this.$forceUpdate()
        }
      }
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>

</style>
