<template>
  <v-card>
    <v-card-title class="text-center">Opravdu potvrdit?</v-card-title>
    <v-card-actions class="d-flex justify-center ">
      <v-btn
        width="250"
        color="green"
        class="rounded-0"
        @click="approve"
        :loading="runningRequest"
      >
        Potvrdit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ApproveReviewCard',
  props: {
    review: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      runningRequest: false
    }
  },
  methods: {
    approve () {
      this.runningRequest = true
      this.$http.post(`/admin/reviews/${this.review.id}/approved`, {
        approved: 1
      })
        .then(res => {
          this.$emit('edit-success')
        })
        .catch(() => {
          this.$emit('edit-failure')
        })
    }
  }
}
</script>

<style scoped>

</style>
