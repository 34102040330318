var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"mb-12"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.property.getRooms(),"sort-by":"name","footer-props":{'items-per-page-options': [10, 20, 50] }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Jednotky")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.addNewRoom}},[_vm._v(" Přidat jednotku ")])],1)]},proxy:true},{key:"item.beds",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.getBedCount())+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.getName())+" ")]}},{key:"item.capacity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.getCapacity())+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){_vm.cloneRoom({ tmpId: item.getTmpId() })}}},[_vm._v(" Duplikovat ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"green"},on:{"click":function($event){_vm.editRoom({ tmpId: item.getTmpId() }); _vm.goToStep(4)}}},[_vm._v(" Editovat ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){_vm.deleteRoom({ tmpId: item.getTmpId() })}}},[_vm._v(" Smazat ")])]}}])})],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.goToStep(2)}}},[_vm._v(" Předchozí krok ")]),(_vm.property.getRooms().length)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.goToStep(6)}}},[_vm._v(" Přejít na foto ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }