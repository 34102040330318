<template>
  <v-card>
    <v-tabs
      v-model="tab"
      centered
    >
      <v-tab>
        Náhled
      </v-tab>
      <v-tab>
        Formulář
      </v-tab>
      <v-tab>
        Nastavení ceny
      </v-tab>
      <v-tab>
        Recenze
      </v-tab>
      <v-tab>
        Statistiky
      </v-tab>
      <v-tab v-if="property.approvalProcessState === 3">
        Inzerce
      </v-tab>
      <!-- i guess zobrazovat, jen kdyz nekdy byla zaplacena inzerce ?-->
      <v-tab v-if="property.advertising.length">
        Topování
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container>
          <DetailMiniCard
            v-if="property.id"
          ></DetailMiniCard>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <EditPropertyStepperWrapper :property-owner-id="user.id"/>
      </v-tab-item>
      <v-tab-item>
        <PropertyPriceSettings></PropertyPriceSettings>
      </v-tab-item>
      <v-tab-item>
        <ReviewsOverview type="property" />
      </v-tab-item>
      <v-tab-item>
        <PropertyViewsChart></PropertyViewsChart>
      </v-tab-item>
      <v-tab-item v-if="property.approvalProcessState === 3">
        <AdvertisingSettings></AdvertisingSettings>
      </v-tab-item>
      <!-- i guess zobrazovat, jen kdyz nekdy byla zaplacena inzerce ?-->
      <v-tab-item v-if="property.advertising.length">
        <PromoSettings />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import PropertyViewsChart from '../../components/charts/PropertyViewsChart'
import PropertyPriceSettings from '../../components/price/PropertyPriceSettings'
import AdvertisingSettings from '../../components/advertising/AdvertisingSettings'
import { mapGetters, mapMutations } from 'vuex'
import DetailMiniCard from '@/components/property/detail/DetailMiniCard'
import EditPropertyStepperWrapper from '@/components/property/EditPropertyStepperWrapper'
import ReviewsOverview from '@/components/users/ReviewsOverview'
import PromoSettings from '@/components/promo/PromoSettings'

export default {
  name: 'CardDetail',
  components: {
    PromoSettings,
    ReviewsOverview,
    EditPropertyStepperWrapper,
    DetailMiniCard,
    AdvertisingSettings,
    PropertyPriceSettings,
    PropertyViewsChart
  },
  data () {
    return {
      tab: 0
    }
  },
  methods: {
    loadData () {
      this.$http.get(`/host/properties/${this.$route.params.id}`)
        .then(res => {
          this.setProperty(res.data)
        })
        .catch(err => {
          if (err.response.status === 400) {
            this.setAlerts([])
            this.$router.push({
              name: 'host.properties.property',
              params: { id: err.response.data.redirect }
            })
          }
          if (err.response.status === 404) {
            this.setAlerts([{
              message: 'Objekt nenalezen',
              type: 'error'
            }])
          }
        })
    },
    ...mapMutations({
      setProperty: 'detailViews/setProperty'
    })
  },
  computed: {
    ...mapGetters({
      user: 'userAuth/user',
      property: 'detailViews/property'
    })
  },
  created () {
    this.loadData()
    this.$http.get('/admin/global-advertising-settings')
      .then(res => {
        this.$store.state.detailViews.globalAdvertisingSettings = res.data
      })
  },
  watch: {
    '$route.params': {
      deep: true,
      handler () {
        this.loadData()
      }
    }
  }
}
</script>

<style scoped>

</style>
