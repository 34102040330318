<template>
  <PropertyCardStepper />
</template>

<script>
import PropertyCardStepper from '@/components/property/PropertyCardStepper'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'EditPropertyStepperWrapper',
  props: {
    propertyOwnerId: {
      required: true,
      type: Number
    }
  },
  components: { PropertyCardStepper },
  computed: {
    ...mapGetters({
      property: 'detailViews/property',
      propertyCard: 'propertyCard/property',
      editedRoom: 'propertyCard/editedRoom'
    })
  },
  methods: {
    ...mapMutations({
      resetProperty: 'propertyCard/resetProperty',
      createRoom: 'propertyCard/createRoom',
      createBed: 'propertyCard/createBed',
      removeBed: 'propertyCard/removeBed',
      updateBedType: 'propertyCard/updateBedType',
      updateBedQuantity: 'propertyCard/updateBedQuantity',
      saveEquipment: 'propertyCard/saveEquipment',
      saveEditedRoom: 'propertyCard/saveEditedRoom'
    })
  },
  created () {
    this.resetProperty()
    this.propertyCard.id = this.property.id
    this.propertyCard.name = this.property.name
    this.propertyCard.propertyTypeId = this.property.propertyTypeId
    this.propertyCard.descriptionPreview = this.property.descriptionPreview
    this.propertyCard.description = this.property.description
    this.propertyCard.ownerId = this.property.ownerId
    this.propertyCard.stars = this.property.stars
    this.propertyCard.contactPersonName = this.property.contactPersonName
    this.propertyCard.contactPersonPhone = this.property.contactPersonPhone
    this.propertyCard.contactPersonEmail = this.property.contactPersonEmail
    this.propertyCard.checkInFrom = this.property.checkInFrom
    this.propertyCard.checkOutFrom = this.property.checkOutFrom
    this.propertyCard.checkInTo = this.property.checkInTo
    this.propertyCard.checkOutTo = this.property.checkOutTo
    this.propertyCard.hostLanguages = this.property.hostLanguages.map(language => language.language.toUpperCase()) // pro jistotu
    this.propertyCard.locality = {
      displayName: this.property.localityDisplayName || null,
      country: this.property.country || null,
      region: this.property.region || null,
      city: this.property.city || null,
      streetNumber: this.property.streetNumber || null,
      street: this.property.street || null,
      latitude: this.property.latitude,
      longitude: this.property.longitude
    }
    this.property.rooms.forEach(room => {
      this.createRoom()
      this.$store.state.propertyCard.editedRoom.setName(room.name)
      this.$store.state.propertyCard.editedRoom.setCapacity(room.capacity)
      this.$store.state.propertyCard.editedRoom.setPrice(room.price)
      this.$store.state.propertyCard.editedRoom.setRoomsQuantity(room.roomsQuantity)

      // delete default empty Bed
      this.removeBed({ tmpId: 1 })
      let currentBedTmpId = 2
      room.beds.forEach(bed => {
        this.createBed()
        this.updateBedType({
          value: bed.type,
          tmpId: currentBedTmpId
        })
        this.updateBedQuantity({
          value: bed.quantity,
          tmpId: currentBedTmpId
        })
        currentBedTmpId++
      })

      /*
      DB vraci v JSONu format {
        [id]: {
          name: String,
          value: {Number|Boolean}
        },
        ...
      }
      */
      const mappedEquipment = {}
      Object.entries(JSON.parse(room.equipment)).forEach(([id, objValue]) => {
        mappedEquipment[id] = objValue.value
      })
      this.$store.state.propertyCard.editedRoom.setEquipment(mappedEquipment)

      this.saveEditedRoom()
    })
  }
}

// eslint-disable-next-line no-unused-vars
function arrangeEquipment (roomEquipment) {
  const finalArr = []
  for (let i = 0; i < 8; i++) {
    if (i === 3 || i === 5) {
      finalArr[i] = []
    } else {
      finalArr[i] = {}
    }
  }

  /**
   * @typedef {{
   *   [Number]: {
   *     equipmentGroup: Number,
   *     equipmentType: Number
   *   }
   * }} EquipmentObject
   * /
   /**
   * @var roomEquipment EquipmentObject
   */

  if (roomEquipment) {
    // console.log(roomEquipment)
    Object.entries(roomEquipment).forEach(([equipmentId, equipmentValue]) => {
      // console.log(equipmentValue)
    })

    roomEquipment.forEach((eq) => {
      if (eq.equipmentType.equipmentGroup === 3 || eq.equipmentType.equipmentGroup === 5) {
        finalArr[eq.equipmentType.equipmentGroup].push({
          [eq.equipmentType.id]: eq.value
        })
      } else {
        finalArr[eq.equipmentType.equipmentGroup][eq.equipmentType.id] = eq.value
      }
    })
  }

  // eslint-disable-next-line no-unreachable
  return finalArr
}
/* function formatTime (unixObject) {
  const date = new Date(unixObject.timestamp * 1000)
  return date.toISOString().slice(11, 16)
} */
</script>

<style scoped>

</style>
