export default {
  data () {
    return {
      deleteDialog: false
    }
  },
  methods: {
    showDeleteSuccess () {
      this.deleteDialog = false
      this.$store.dispatch('alerts/setAlerts', [{
        message: 'Úspěšně smazáno',
        type: 'success'
      }])
    },
    deleteFailure () {
      this.deleteDialog = false
    },
    showDeleteFailure () {
      this.$store.dispatch('alerts/setAlerts', [{ message: 'Při archivaci došlo k chybě', type: 'error' }])
      this.deleteDialog = false
    }
  }
}
