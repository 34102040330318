<template>
  <div>
    <v-row>
      <v-col id="property-info" cols="12">
        <div id="property-top-detail">
          <div class="d-flex" id="property-name">
            <div class="property-title mr-4 my-5">{{ property.name }}</div>
            <div id="user-contdols" class="d-flex">
              <div id="add-to-favourites" v-if="user.id">
                <v-progress-circular
                  v-if="fetchingLiked"
                  color="red"
                  indeterminate
                />
                <v-btn
                  icon
                  v-else
                  @click="toggleLikedProperty"
                >
                  <v-icon :color="likedProperty ? 'red' : 'primary'">
                    {{ likedProperty ? 'mdi-heart' : 'mdi-heart-outline' }}
                  </v-icon>
                </v-btn>
              </div>
              <div id="user-share">
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon color="primary">mdi-share-variant</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item-group>

                      <v-list-item>
                        <v-list-item-title>Sdílet na twitteru</v-list-item-title>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-title>Sdílet na facebooku</v-list-item-title>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-title
                          @click="copyLink"
                        >Kopírovat odkaz
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </div>
          <div id="property-quick-info">
              <div class="mb-3">
                <span class="property-reviews">
                    <span class="property-rating-value align-self-center" v-if="property.rating">
                      <v-icon color="hotelsYellow">mdi-star</v-icon>
                            ({{ property.rating }}) - xx, recenzí
                    </span>
                          <!--                  <v-rating-->
                          <!--                    readonly-->
                          <!--                    color="orange"-->
                          <!--                    half-increments-->
                          <!--                    :value="property.rating"-->
                          <!--                    length="5"-->
                          <!--                    size="22"-->
                          <!--                    v-if="property.rating"-->
                          <!--                  >-->
                          <!--                  </v-rating>-->
                    <span v-if="!property.rating">
                      Zatím žádná hodnocení
                    </span>
                </span>
              </div>
              <div class="mb-4">
                <div class="d-flex" id="property-place">
                  <v-icon color="hotelsBlue mr-2">mdi-map-marker</v-icon>
                  <span class="ml-3">{{ property.localityDisplayName }}</span>
                </div>
              </div>
          </div>
        </div>
        <Gallery
          :main-photo-path="property.miniPhotoPath"
          :photo-paths="property.propertyPhotos.map(photo => photo.photoPath)"
        ></Gallery>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="mt-3">
          <span class="description-heading">Popis</span>
          <div id="property-description" v-html="property.description"></div>
        </div>
        <div class="custom-heading">Vybavení</div>
        <div class="d-flex flex-wrap">
          <div
            v-for="(eq, index) in equipmentOverview" :key="index"
            class="equipment-check align-center d-flex mr-2 ml-2"
          >
            <v-icon class="mr-1" color="green">mdi-check</v-icon>{{ eq.name }}
          </div>
        </div>
      </v-col>
      <v-col cols="3">
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Gallery from './Gallery'
import { mapGetters } from 'vuex'

export default {
  name: 'DetailMiniCard',
  components: { Gallery },
  data () {
    return {
      likedProperty: false,
      fetchingLiked: false,
      backendPublicUrl: process.env.VUE_APP_BACKEND_PUBLIC_URL
    }
  },
  computed: {
    equipmentOverview () {
      const finalObj = {}
      /**
       * @var this.property {
       *   rooms: Array
       * }
       */
      this.property.rooms.forEach(room => {
        if (room.equipment) {
          const roomEquipment = JSON.parse(room.equipment)
          Object.keys(roomEquipment)
            .forEach(equipmentTypeId => {
              if (!(equipmentTypeId in finalObj)) {
                finalObj[equipmentTypeId] = roomEquipment[equipmentTypeId]
              }
            })
        }
      })
      return finalObj
    },
    ...mapGetters({
      property: 'detailViews/property',
      isLogged: 'userAuth/isLogged',
      isUser: 'userAuth/isUser',
      user: 'userAuth/user'
    })
  },
  methods: {
    toggleLikedProperty () {
      this.likedProperty = !this.likedProperty
      this.fetchingLiked = true
      this.$http.post(`/liked-properties/${this.property.id}/${this.likedProperty ? 'add' : 'remove'}`)
        .then(() => {
          this.fetchingLiked = false
        })
    },
    copyLink () {
      navigator.clipboard.writeText(location.href)
    }
  },
  created () {
    if (!this.user.id) {
      return
    }
    this.fetchingLiked = true
    this.$http.get(`/liked-properties/${this.property.id}/is-liked`)
      .then(res => {
        this.likedProperty = res.data.liked
      })
      .finally(() => {
        this.fetchingLiked = false
      })
  }
}
</script>

<style scoped>
#property-name {
  align-items: center;
  justify-content: space-between;
}

.property-title {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 28px;
}

.equipment-check {
  color: green;
  font-size: 15px !important;
}

.property-reviews {
  background-color: #dbf3ff;
  border-radius: 18px;
  padding: 8px;
  font-family: 'Readex Pro', sans-serif;
  font-size: 12px;
}
#property-description{
  font-family: 'Readex Pro', sans-serif;
  color: #6a6a6a;
}
.description-heading{
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 28px;
}
</style>
