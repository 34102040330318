<template>
  <div>
    <NewPromoForm />
    <!-- i guess tady historie ??? -->
    <PromoHistoryCard
      v-for="promo in property.promo"
      :key="promo.id"
      :promo="promo"
    />
  </div>
</template>

<script>
import NewPromoForm from '@/components/promo/NewPromoForm'
import { mapGetters } from 'vuex'
import PromoHistoryCard from '@/components/promo/PromoHistoryCard'
export default {
  name: 'PromoSettings',
  components: { PromoHistoryCard, NewPromoForm },
  computed: {
    ...mapGetters({
      property: 'detailViews/property'
    })
  }
}
</script>

<style scoped>

</style>
