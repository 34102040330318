<template>
  <v-card>
    <v-card-title class="text-h5 text-right justify-end">
      <v-btn
        icon
        @click="$emit('photo-closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-img v-if="photoUrl" :src="photoUrl"></v-img>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    photoUrl: {
      required: true,
      type: [String, null]
    }
  },
  name: 'ShowPhotoCard'
}
</script>

<style scoped>

</style>
