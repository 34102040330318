  <template>
    <v-stepper v-model="step" alt-labels>
      <v-stepper-header class="center-label">
        <v-stepper-step
          :complete="step > 1"
          step="1"
        >
          Typ objektu
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="step > 2"
          step="2"
        >
          Základní info
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="step > 3"
          step="3">
          Pokoje a cena
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step
          :complete="step > 4"
          step="4">
          Vybavení a služby
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="step > 5"
          step="5">
          Dodatečné vybavení

        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="step > 6"
          step="6">
          Fotky
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="step > 7"
          step="7">
          Check-in, check-out
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step
          :complete="step > 8"
          step="8">
          Dokončení
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <Step1></Step1>
        </v-stepper-content>
        <v-stepper-content step="2">
          <Step2></Step2>
        </v-stepper-content>
        <v-stepper-content step="3">
          <Step3></Step3>
        </v-stepper-content>
        <v-stepper-content step="4">
          <Step4></Step4>
        </v-stepper-content>
        <v-stepper-content step="5">
          <Step5></Step5>
        </v-stepper-content>
        <v-stepper-content step="6">
          <Step6></Step6>
        </v-stepper-content>
        <v-stepper-content step="7">
          <Step7></Step7>
        </v-stepper-content>
        <v-stepper-content step="8">
          <Step8
            @save-success="goBack"
          ></Step8>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </template>

<script>
import Step1 from './createPropertySteps/Step1'
import Step2 from './createPropertySteps/Step2'
import Step3 from './createPropertySteps/Step3'
import Step4 from './createPropertySteps/Step4'
import Step5 from './createPropertySteps/Step5'
import Step6 from './createPropertySteps/Step6'
import Step7 from './createPropertySteps/Step7'
import Step8 from './createPropertySteps/Step8'
export default {
  name: 'PropertyCardStepper',
  components: { Step8, Step7, Step6, Step5, Step4, Step3, Step2, Step1 },
  data () {
    return {
      e1: 5
    }
  },
  computed: {
    step: {
      get () {
        return this.$store.state.propertyCard.formStep
      },
      set (step) {
        this.$store.state.propertyCard.formStep = step
      }
    }
  },
  methods: {
    /**
     * Redirects to properties overview
     * @return void
     */
    goBack () {
      this.$router.push({ name: 'host.properties' })
    }
  },
  beforeDestroy () {
    this.$store.dispatch('propertyCard/resetCard')
  }
}
</script>

<style scoped>
>>>.center-label .v-stepper__label{
  text-align: center !important;
}
>>>.v-stepper__step__step{
  border-radius: 10%;
}
</style>
