<template>
  <div>
    <v-container
      class="mb-12"
    >
      <v-data-table
        :headers="headers"
        :items="property.getRooms()"
        sort-by="name"
        :footer-props="{'items-per-page-options': [10, 20, 50] }"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Jednotky</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
            color="primary"
            @click="addNewRoom"
            >
              Přidat jednotku
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.beds="{ item }">
          {{ item.getBedCount() }}
        </template>
        <template v-slot:item.name="{ item }">
          {{ item.getName() }}
        </template>
        <template v-slot:item.capacity="{ item }">
          {{ item.getCapacity() }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            small
            class="mr-2"
            @click="cloneRoom({ tmpId: item.getTmpId() })"
            color="primary"
          >
            Duplikovat
          </v-btn>
          <v-btn
            small
            class="mr-2"
            @click="editRoom({ tmpId: item.getTmpId() }); goToStep(4)"
            color="green"
          >
            Editovat
          </v-btn>
          <v-btn
            small
            class="mr-2"
            @click="deleteRoom({ tmpId: item.getTmpId() })"
            color="red"
          >
            Smazat
          </v-btn>
        </template>
      </v-data-table>
    </v-container>

    <v-btn
      text
      @click="goToStep(2)"
    >
      Předchozí krok
    </v-btn>

    <!-- TODO: fráze -->
    <v-btn
      v-if="property.getRooms().length"
      text
      color="primary"
      @click="goToStep(6)"
    >
      Přejít na foto
    </v-btn>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {
  name: 'Step3',
  data () {
    return {
      headers: [
        { text: 'Název', value: 'name', sortable: false },
        { text: 'Kapacita', value: 'capacity', sortable: false },
        { text: 'Počet postelí', value: 'beds', sortable: false },
        { text: 'Akce', value: 'actions', sortable: false }
      ]
    }
  },
  methods: {
    addNewRoom () {
      this.createRoom()
      this.$forceUpdate()
      this.goToStep(4)
    },
    ...mapMutations({
      editRoom: 'propertyCard/editRoom',
      deleteRoom: 'propertyCard/deleteRoom',
      goToStep: 'propertyCard/goToStep',
      createRoom: 'propertyCard/createRoom',
      cloneRoom: 'propertyCard/cloneRoom'
    }),
    ...mapActions({
      addPropertyRoom: 'propertyCard/addRoom'
    })
  },
  computed: {
    ...mapGetters({
      property: 'propertyCard/property'
    })
  }
}
</script>

<style scoped>

</style>
