<template>
  <div>
    <v-container
      class="mb-12"
    >
      <v-form v-model="formValid" ref="roomForm">
        <v-row>
          <v-col>
            <v-text-field
              ref="nameInput"
              v-model="editedRoomName"
              :rules="[validation.required, validation.maxLength(255)]"
              outlined
              label="Název"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <h3 class="display-2">Postele</h3>
        <v-row>
          <h2 class="display-1 pl-3 pb-3 pt-2">Jaké postele se zde nachází?</h2>
          <v-col cols="12">
            <div class="d-flex" v-for="bed in $store.state.propertyCard.editedRoom.getBeds()" :key="bed.getTmpId()">
              <v-select
                :rules="[validation.required]"
                label="Typ postele"
                outlined
                @input="(value) => {updateBedType({ value, tmpId: bed.getTmpId()}); $forceUpdate()}"
                :items="bedTypes"
                :value="bed.getType()"
                class="rounded-0">
              </v-select>
              <span class="px-4">X</span>
              <v-text-field
                :rules="[validation.required, validation.greaterThan(0)]"
                label="Počet postelí"
                outlined
                :value="bed.getQuantity()"
                @input="(value) => {updateBedQuantity({ value, tmpId: bed.getTmpId()}); $forceUpdate()}"
                class="rounded-0"
                type="number"
                min="1"
                style="width: 5%"
              >
              </v-text-field>
              <v-btn
                color="error"
                @click.prevent="removeBed({tmpId: bed.getTmpId()}); $forceUpdate()"
              >
                X
              </v-btn>
            </div>

            <v-btn
              text
              color="primary"
              @click="addBed(); $forceUpdate() /* private properties = potrebuju forceupdate */"
            >
              <v-icon>
                mdi-plus
              </v-icon>
              Přidat postel
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
              label="Maximální kapacita"
              outlined
              ref="capacityInput"
              class="rounded-0"
              type="number"
              :rules="[validation.required, validation.greaterThan(0)]"
              v-model="editedRoomCapacity"
              min="1"
              append-icon="mdi-account"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Počet pokojů"
              outlined
              min="1"
              ref="roomsQuantityInput"
              class="rounded-0"
              type="number"
              :rules="[validation.required, validation.greaterThan(0)]"
              v-model.number="editedRoomRoomsQuantity"
              append-icon="mdi-account"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Základní cena za noc"
              outlined
              min="1"
              ref="priceInput"
              class="rounded-0"
              v-model.number="editedRoomPrice"
              type="number"
              :rules="[validation.required, validation.greaterThan(0)]"
              prepend-inner-icon="mdi-cash">
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-btn
      @click="goToStep(3)"
    >
      Zrušit
    </v-btn>
    <v-btn
      color="primary"
      :disabled="nextStepButtonDisabled"
      @click="nextStep"
    >
      Pokračovat
    </v-btn>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { EventBus } from '@/events/event_bus'

export default {
  name: 'Step4',
  data () {
    return {
      formValid: true,
      bedTypes: [
        { text: 'Jednolůžková postel / 90x200cm', value: 1 },
        { text: 'Dvoulůžková postel / 180x200cm', value: 2 },
        { text: 'Přistýlka', value: 3 },
        { text: 'Dětská postýlka', value: 4 }
      ],
      beds: []
    }
  },
  methods: {
    nextStep () {
      this.editedRoom.beds = this.countQuantity(this.beds, 'type')
      this.goToStep(5)
    },
    ...mapMutations({
      goToStep: 'propertyCard/goToStep',
      addBed: 'propertyCard/createBed',
      updateBedType: 'propertyCard/updateBedType',
      updateBedQuantity: 'propertyCard/updateBedQuantity',
      removeBed: 'propertyCard/removeBed'
    })
  },
  computed: {
    nextStepButtonDisabled () {
      return !this.formValid || !this.$store.state.propertyCard.editedRoom.getBeds().length
    },
    /*
    !!!! editedRoom - getter - object with name, capacity, roomsQuantity, beds, price, equipment
        $store.state.editedRoom - Room instance
    */
    ...mapGetters({
      step: 'propertyCard/formStep',
      editedRoom: 'propertyCard/editedRoom'
    }),
    editedRoomCapacity: {
      set (capacity) {
        this.$store.state.propertyCard.editedRoom.setCapacity(capacity)
      },
      get () {
        return this.editedRoom.capacity
      }
    },
    editedRoomRoomsQuantity: {
      set (quantity) {
        this.$store.state.propertyCard.editedRoom.setRoomsQuantity(quantity)
      },
      get () {
        return this.editedRoom.roomsQuantity
      }
    },
    editedRoomPrice: {
      set (price) {
        this.$store.state.propertyCard.editedRoom.setPrice(price)
      },
      get () {
        return this.editedRoom.price
      }
    },
    editedRoomName: {
      set (name) {
        this.$store.state.propertyCard.editedRoom.setName(name)
      },
      get () {
        return this.editedRoom.name
      }
    }
  },
  watch: {
    step (newVal) {
      if (newVal === 4) {
        // eslint-disable-next-line eqeqeq
        if (!this.editedRoom.capacity) {
          this.$refs.capacityInput.reset()
        }
        if (!this.editedRoom.roomsQuantity) {
          this.$refs.roomsQuantityInput.reset()
        }
        if (!this.editedRoom.name) {
          this.$refs.nameInput.reset()
        }
        if (!this.editedRoom.price) {
          this.$refs.priceInput.reset()
        }
        this.$forceUpdate()
      }
    }
  },
  created () {
    EventBus.$on('reset-forms', () => {
      this.$refs.roomForm.reset()
    })
  }
}
</script>

<style scoped>

</style>
