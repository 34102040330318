<template>
  <div v-if="photoUrls.length || mainPhotoUrl">
    <v-img
      :src="mainPhotoUrl || photoUrls[0]"
      id="main-gallery-photo"
      @click="index = 0"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <img class="gallery-image" v-for="(image, i) in photoUrls" :src="image" :key="i" @click="index = i" />
    <vue-gallery-slideshow :images="photoUrls" :index="index" @close="index = null">
    </vue-gallery-slideshow>
    <div class="text-center">
      <v-pagination
        v-model="page"
        :length="1"
      ></v-pagination>
    </div>
  </div>
  <v-card v-else>
    <v-card-text>
      Žádné foto k zobrazení
    </v-card-text>
  </v-card>
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow'

export default {
  props: {
    mainPhotoPath: {
      type: String,
      required: false // muze byt null v db
    },
    photoPaths: {
      type: Array,
      required: true
    }
  },
  name: 'Gallery',
  components: {
    VueGallerySlideshow
  },
  data () {
    return {
      page: 1,
      index: null
    }
  },
  computed: {
    /**
     * Prepends mainPhotoUrl with backend public url
     * Returns null if mainPhotoUrl is empty
     *
     * @return {string|null}
     */
    mainPhotoUrl () {
      if (!this.mainPhotoPath) {
        return null
      }

      return process.env.VUE_APP_BACKEND_PUBLIC_URL + this.mainPhotoPath
    },
    /**
     * Prepends photoUrls with backend public url
     * @return {String[]}
     */
    photoUrls () {
      return this.photoPaths.map(path => process.env.VUE_APP_BACKEND_PUBLIC_URL + path)
    }
  }
}
</script>

<style scoped>
#main-gallery-photo{
  width: auto;
  margin: 0 1px 20px 1px;
}
.gallery-image{
  max-width: 60px;
  height: auto;
  margin: 0 1px 0 1px;
}
>>>.vgs__container{
  margin-top: 10vh;
}
</style>
