<template>
  <v-form
    ref="form"
    v-model="formValid"
    v-if="!loadingSettings"
  >
    <v-row>
      <v-col cols="6">
        <v-menu
          ref="monthFromMenu"
          v-model="monthFromMenu"
          :close-on-content-click="false"
          :return-value.sync="form.monthFrom"
          max-width="290px"
          min-width="auto"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              ref="monthFromInput"
              v-model="form.monthFrom"
              v-bind="attrs"
              v-on="on"
              :rules="[validation.required, formRules.validSpan, formRules.maxSpanYear]"
              class="rounded-0"
              label="Začátek inzerce"
              outlined
              prepend-inner-icon="mdi-calendar"
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            ref="monthFromPicker"
            v-model="form.monthFrom"
            no-title
            scrollable
            type="month"
          >
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="monthFromMenu = false"
            >
              Zrušit
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="$refs.monthFromMenu.save(form.monthFrom)"
            >
              Uložit
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6">
        <v-menu
          ref="monthToMenu"
          v-model="monthToMenu"
          :close-on-content-click="false"
          :return-value.sync="form.monthTo"
          max-width="290px"
          min-width="auto"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              ref="monthFromInput"
              v-model="form.monthTo"
              v-bind="attrs"
              v-on="on"
              :rules="[validation.required, formRules.validSpan, formRules.maxSpanYear]"
              class="rounded-0"
              label="Konec inzerce"
              outlined
              prepend-inner-icon="mdi-calendar"
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            ref="monthToPicker"
            v-model="form.monthTo"
            no-title
            scrollable
            type="month"
          >
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="monthToMenu = false"
            >
              Zrušit
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="$refs.monthToMenu.save(form.monthTo)"
            >
              Uložit
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12">
        <v-checkbox
          v-model="form.renewable"
          label="Automatické obnovení"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer />
      <v-col>
        <v-radio-group v-model="form.paymentType">
          <template v-slot:label>
            <span class="text-h6">Platba</span>
          </template>
          <v-radio :value="1" label="Kredit"/>
          <v-radio :value="2" label="Platební brána" />
        </v-radio-group>
      </v-col>
      <v-col>
        <v-select
          label="Kupon"
          :rules="[formRules.validCouponMinPrice]"
          :items="coupons"
          v-model="form.coupon"
          item-text="code"
          return-object
          clearable
        />
      </v-col>
    </v-row>
    <v-row>
      <v-spacer />
      <v-col class="d-flex flex-column justify-end align-end" cols="8">
        <div
          class="red--text darken-4"
          v-if="advertisingExists"
        >Na zadané období již byla zaplacena inzerce
        </div>
        <div class="d-flex">
          <div class="mr-5">
            <div>Cena za inzerci: {{totalPrice}} Kč</div>
            <div
              v-if="form.coupon && totalPrice !== '-'"
            >Cena s kupónem: {{totalPriceWithCoupon}} Kč</div>
          </div>

          <v-btn
            :disabled="!formValid || advertisingExists"
            :loading="fetchingData"
            class="rounded-0"
            color="primary"
            @click="save"
          >
            Zaplatit
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-form>
  <v-skeleton-loader
    type="article"
    v-else
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AdvertisingForm',
  data () {
    return {
      formValid: true,
      coupons: [],
      form: {
        monthFrom: null,
        monthTo: null,
        renewable: false,
        dateFrom: null,
        dateTo: null,
        paymentType: 1,
        coupon: null
      },
      monthFromMenu: false,
      monthToMenu: false,
      fetchingData: false
    }
  },
  computed: {
    ...mapGetters({
      property: 'detailViews/property',
      globalAdvertisingSettings: 'detailViews/globalAdvertisingSettings',
      loadingSettings: 'detailViews/loadingSettings'
    }),
    advertisingExists () {
      if (!this.form.monthFrom || !this.form.monthTo || !this.formValid) {
        return false
      }
      const filtered = this.property.advertising.filter(ad => {
        console.log(ad)
        // kontrola datumu
        const formDateFrom = new Date(this.dateFrom)
        const formDateTo = new Date(this.dateTo)
        const adDateFrom = new Date(ad.dateFrom)
        const adDateTo = new Date(ad.dateTo)
        // zacatek v existujicim
        if (adDateFrom <= formDateFrom && formDateFrom <= adDateTo) {
          return true
        }
        // konec v existujicim
        if (adDateFrom <= formDateTo && formDateTo <= adDateTo) {
          return true
        }

        return false
      })
      return !!filtered.length
    },
    totalPrice () {
      // prazdne hodnoty
      if (!this.form.monthFrom || !this.form.monthTo || this.form.monthFrom > this.form.monthTo || this.advertisingExists) {
        return '-'
      }
      let months
      const fromYear = this.form.monthFrom.slice(0, 4)
      const toYear = this.form.monthTo.slice(0, 4)
      const fromMonth = this.form.monthFrom.slice(5)
      const toMonth = this.form.monthTo.slice(5)

      if (fromYear === toYear) {
        months = toMonth - fromMonth + 1
      } else {
        const remainingToNewYear = 12 - fromMonth + 1 // + krajni mesic
        const overlapingFromNewYear = +toMonth

        months = remainingToNewYear + overlapingFromNewYear
      }

      return months === 12 ? this.globalAdvertisingSettings.advertisingYearPrice
        : (months * this.globalAdvertisingSettings.advertisingMonthPrice).toFixed(2)
    },
    totalPriceWithCoupon () {
      if (this.totalPrice === '-' || !this.form.coupon) {
        return '-'
      }

      if (this.form.coupon.type === 1) {
        return (this.totalPrice * (100 - this.form.coupon.value) / 100)
          .toFixed(2)
      } else {
        const tmp = (this.totalPrice - this.form.coupon.value).toFixed(2)
        // pro jistotu
        return tmp >= 0 ? tmp : 0
      }
    },
    formRules () {
      return {
        maxSpanYear: v => {
          const errorMessage = 'Období inzerce nesmí přesáhnout 1 rok'
          // true pro prazdne hodnoty
          if (!this.form.monthFrom || !this.form.monthTo) {
            return true
          }

          const fromYear = this.form.monthFrom.slice(0, 4)
          const toYear = this.form.monthTo.slice(0, 4)
          const fromMonth = this.form.monthFrom.slice(5)
          const toMonth = this.form.monthTo.slice(5)

          // pokud od-do je ve stejnym roce, nemuze to logicky  byt rozsah vetsi nez 1 rok :)
          if (fromYear === toYear) {
            return true
          }
          // rozsah vetsi nez 1 rok
          if (toYear > +fromYear + 1) {
            return errorMessage
          }

          const remainingToNewYear = 12 - fromMonth + 1 // + krajni mesic
          const overlapingFromNewYear = +toMonth

          return remainingToNewYear + overlapingFromNewYear <= 12 || errorMessage
        },
        validSpan: v => {
          // true pro prazdne hodnoty
          if (!this.form.monthFrom || !this.form.monthTo) {
            return true
          }
          return this.form.monthFrom <= this.form.monthTo || 'Neplatný rozsah hodnot'
        },
        validCouponMinPrice: v => {
          if (!v || this.totalPrice === '-') {
            return true
          }
          return v.minPrice <= this.totalPrice || `Minimální částka pro použití kuponu: ${v.minPrice}`
        }
      }
    },
    dateFrom () {
      if (!this.form.monthFrom) {
        return null
      }
      return this.form.monthFrom + '-01'
    },
    dateTo () {
      if (!this.form.monthFrom) {
        return null
      }
      /*  nastaveni dateTo na posledni den ve zvolenem mesici
          - vybrat 1. den v poslednim mesici
          - pricist 1 mesic
          - odecist 1 den
      * */
      const dateTo = new Date(this.form.monthTo + '-01')
      if (dateTo.getMonth() === 11) {
        // pokud je zvoleny mesic prosinec, nastavuje se leden nasledujiciho roku
        dateTo.setMonth(0)
        dateTo.setFullYear(dateTo.getFullYear() + 1)
      } else {
        // jinak se jen pricte mesic
        dateTo.setMonth(dateTo.getMonth() + 1)
      }
      dateTo.setTime(dateTo.getTime() - 1000 * 60 * 60 * 24)

      return dateTo.toISOString().slice(0, 10)
    }
  },
  methods: {
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    }),
    save () {
      this.fetchingData = true
      const data = {
        ...this.form,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo
      }
      delete data.monthTo
      delete data.monthFrom

      this.$http.post(`/host/properties/${this.property.id}/advertising`, data)
        .then(res => {
          this.$store.state.detailViews.property.advertising.push(res.data)

          if (this.form.coupon) {
            const index = this.coupons.indexOf(this.form.coupon)
            // oddelat kupon, pokud uz nejde pouzit
            if (this.form.coupon.remainingUses === 1) {
              this.coupons.splice(index, 1)
            } else {
              // jinak mu jen snizit pocet pouziti
              this.coupons[index].remainingUses--
            }
          }
          this.$refs.form.reset()
          this.form.paymentType = 1
          this.form.renewable = false
          this.setAlerts([{
            message: 'Platba proběhla úspěšně',
            type: 'success'
          }])
        })
        .catch(err => {
          console.log(err)
          if (err.response.data.message) {
            let errorMessage
            switch (err.response.data.message) {
              case 'NOT_ENOUGH_CREDIT': {
                errorMessage = 'Nedostatek kreditu'
                break
              }
              case 'INVALID_COUPON': {
                errorMessage = 'Neplatný kupón'
                break
              }
              case 'INVALID_MIN_PRICE': {
                errorMessage = `Minimální částka pro použití kupónu ${this.form.coupon.minPrice}`
                break
              }
              case 'INVALID_DATE_SPAN': {
                errorMessage = 'Neplatný rozsah inzerce'
                break
              }
              case 'ADVERTISING_ALREADY_EXISTS': {
                errorMessage = 'Na zadané období již byla zaplacena inzerce'
                break
              }
              case 'PROPERTY_CARD_NOT_APPROVED': {
                errorMessage = 'Karta objektu nebyla schválena'
                break
              }
            }
            this.setAlerts([{
              message: errorMessage,
              type: 'error'
            }])
          }
        })
        .finally(() => {
          this.fetchingData = false
        })
    }
  },
  watch: {
    form: {
      deep: true,
      handler (form) {
        if (form.monthTo && form.monthFrom) {
          // force validace kvuli rozsahum
          this.$refs.form.validate()
        }
      }
    }
  },
  created () {
    this.$http.get(`/users/${this.property.ownerId}/coupons`)
      .then(res => {
        this.coupons = res.data
      })
  }
}
</script>

<style scoped>

</style>
