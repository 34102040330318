<template>
  <v-card>
    <v-card-title class="text-center">Opravdu smazat?</v-card-title>
    <v-card-text>
      Tato akce je nevratná
    </v-card-text>
    <v-card-actions class="d-flex justify-center ">
      <v-btn
        width="250"
        color="red"
        class="rounded-0"
        @click="deleteResource"
        :loading="runningRequest"
      >
        Smazat
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    resource: {
      type: Object,
      required: true
    },
    resourceType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      runningRequest: false
    }
  },
  name: 'DeleteResource',
  methods: {
    deleteResource () {
      this.runningRequest = true
      this.$http.delete(`/${this.resourceType}/${this.resource.id}`)
        .then(() => {
          this.$emit('delete-success')
        })
        .catch(err => {
          this.$emit('delete-failure', {
            status: err.response.status,
            message: err.response.data
          })
        })
        .finally(() => {
          this.runningRequest = false
        })
    }
  }
}
</script>

<style scoped>

</style>
